export default function getRequestHeaders(data) {
  const {
    requestHasFilesFlag,
    requestDownloadFilesFlag,
    requestBlobFlag,
    ...params
  } = data || {};

  return Object.assign(
    {
      headers: requestHasFilesFlag
        ? {
            "Content-Type": "multipart/form-data",
          }
        : {
            "Content-Type": "application/json",
          },
    },
    data && { params },
    requestDownloadFilesFlag && { responseType: "arraybuffer" },
    requestBlobFlag && { responseType: "blob" }
  );
}
