export const entriesToOptions = (
  entry,
  { mappingName = "name", mappingId = "_id", extra }
) => {
  const { [mappingId]: value, [mappingName]: label } = entry;
  return Object.assign({ label, value }, extra && { [extra]: entry[extra] });
};

export const enumToOptions = (enumObj) => {
  const entries = Object.entries(enumObj);
  return entries.map(([value, label]) => ({ value, label }));
}
