// Packages
import React, { useCallback, useState } from "react";
import axios from "axios";
import ReactWizard from "react-bootstrap-wizard";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
// Styling
import "react-bootstrap-wizard/dist/react-wizard.css";
import "./Wizard.css";
// Steps Components
import FirstStep from "../components/register-wizard/first-step";
import SecondStep from "../components/register-wizard/second-step";
import ThirdStep from "../components/register-wizard/third-step";
import FourthStep from "../components/register-wizard/fourth-step";
import FifthStep from "../components/register-wizard/fifth-step";
import { catchHandler, getRequestHeaders } from "../utils";

const stepNames = {
  first: "Pasul 1",
  second: "Pasul 2",
  third: "Pasul 3",
  fourth: "Pasul 4",
  fifth: "Pasul 5",
};

const steps = [
  { stepName: stepNames.first, component: FirstStep },
  { stepName: stepNames.second, component: SecondStep },
  { stepName: stepNames.third, component: ThirdStep },
  { stepName: stepNames.fourth, component: FourthStep },
  { stepName: stepNames.fifth, component: FifthStep },
];

const Wizard = React.memo((props) => {
  const history = useHistory();
  const [locked, setLocked] = useState(false);
  const registerUser = useCallback((formData) => {
    axios
      .post("/api/wizard/register", formData, getRequestHeaders())
      .then(() => {
        toast.success("Utilizatorul a fost creat cu succes!");
        setTimeout(() => {
          history.push("/registration-complete");
          setLocked(false);
        }, 500);
      })
      .catch((err) => {
        setLocked(false);
        catchHandler(null, err);
      });
  }, []);

  const finishButtonClick = useCallback(
    (allStates) => {
      if (locked) return;
      setLocked(true);

      const {
        birthDate,
        collaboration,
        existingMember,
        membershipYear,
        fileRef: notarialAgreementFileRef,
      } = allStates[stepNames.third];
      const {
        Curs: course,
        Disciplina: subject,
        Locatie: location,
        fileRef: identityCardFileRef,
      } = allStates[stepNames.fourth];
      const { formValues } = allStates[stepNames.fifth];
      const {
        Email: email = "",
        Nume: lastName = "",
        Prenume: firstName = "",
        CNP: nationalId = "",
        Telefon: phoneNumber = "",
      } = formValues;
      const name = `${firstName} ${lastName}`.trim();

      registerUser({
        name,
        email,
        nationalId,
        birthDate,
        collaboration,
        existingMember,
        membershipYear,
        course,
        subject,
        location,
        identityCardFileRef,
        notarialAgreementFileRef,
        phoneNumber,
        formValues,
      });
    },
    [locked]
  );

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col>
          <ReactWizard
            steps={steps}
            navSteps
            title="Formular inregistrare"
            headerTextCenter
            validate
            color="blue"
            finishButtonClick={finishButtonClick}
            previousButtonText={"Inapoi"}
            nextButtonText={"Inainte"}
            finishButtonText={"Pasul Urmator"}
          />
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Col>
      </Row>
    </Container>
  );
});

export default Wizard;
