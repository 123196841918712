import React from "react";
import UserMetadataForm from "../forms/user-metadata";
import { validationSchemaUserMetadata } from "../forms/schemas/user-metadata";

class FifthStep extends React.Component {
  state = {
    validated: false,
    formValues: {},
  };

  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
  }

  // Important for Wizard
  isValidated() {
    const formValues = this.formRef.current.values;
    this.setState({ formValues, validated: true });
    this.formRef.current.validateForm();

    try {
      validationSchemaUserMetadata.validateSync(formValues);
      return true; // Form is valid
    } catch (error) {
      return false; // Form is invalid
    }
  }

  render() {
    const { validated } = this.state;

    return (
      <>
        <h5>Disclaimer</h5>
        <h6>
          <p>
            ATENTIE! Aceasta adresa de e-mail si numarul de telefon vor fi
            folosite pentru a comunica cu tine de acum incolo.
            <br />
            <strong>Nu folosi</strong> o adresa de email sau numar de telefon la
            care nu ai acces!
            <br />
            Datorita limitarilor de exploatare ale aeronavelor noastre de
            scoala, exista limitari in ceea ce priveste inaltimea si masa minima
            si maxima pe care le poti avea. În funcție de acestea, este posibil
            să nu poți participa la pregătirea practică în zbor.
          </p>
        </h6>
        <UserMetadataForm formRef={this.formRef} validated={validated} />
      </>
    );
  }
}

export default FifthStep;
