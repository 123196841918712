export const collaborationTypes = {
    SUBVENTIONAT: "Subventionat",
    CONTRACT: "Contract",
    LOT: "Lot",
};

export const collaborationTypesLabels = {
    [collaborationTypes.SUBVENTIONAT]: "Subventionat",
    [collaborationTypes.CONTRACT]: "Contract",
    [collaborationTypes.LOT]: "Lot",
};

export const existingMemberOptions = {
    NEW: "new",
    EXISTING: "existent",
};

export const taxPaidOptions = [
    {
        value: true,
        label: "Taxa platita",
    },
    {
        value: false,
        label: "Taxa neplatita",
    },
];
