// Packages
import React, { useState, useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import {
  catchHandler,
  getRequestHeaders,
  userRoleOptions,
  getSelectStyles
} from "../utils";
// Styling
import "./Register.css";

const Register = React.memo((props) => {
  const rootPathname = useMemo(() => "/users-management", []);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    email: "",
    role: "",
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .post("/api/auth/register", fields, getRequestHeaders())
        .then((res) => {
          const { email } = res.data;
          toast.success(`Un email a fost trimis catre [${email}] pentru activarea user-ului.`);
          history.push(rootPathname);
        })
        .catch((err) => {
          const { email = "", role = "" } = catchHandler(history, err);
          setLoading(false);
          setErrors({ email, role });
        });
    },
    [history, fields, rootPathname]
  );

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onSubmit(event);
      }
    },
    [onSubmit]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onSelectChange = useCallback((option, action) => {
    const { value = "" } = option || {};
    const { name = "" } = action || {};
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Inrolare utilizator</h2>
            <Form onKeyPress={onKeyPress}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  invalid={Boolean(errors["email"])}
                  value={fields["email"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["email"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="role">Rol</Label>
                <Select
                  name="role"
                  className={Boolean(errors["role"]) ? "is-invalid" : ""}
                  placeholder="Alegeti un rol"
                  noOptionsMessage={() => <span>Fara rezultat</span>}
                  isClearable={true}
                  onChange={onSelectChange}
                  styles={getSelectStyles(Boolean(errors["role"]))}
                  value={
                    fields["role"] ? userRoleOptions[fields["role"]] : null
                  }
                  options={Object.values(userRoleOptions)}
                />
                <FormFeedback>{errors["role"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default Register;
