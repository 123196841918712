// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import { catchHandler, getRequestHeaders, getSelectStyles } from "../utils";
// Styling
import "./EditLocation.css";

const EditLocation = React.memo((props) => {
  const { id: locationId } = useParams();
  const rootPathname = useMemo(() => "/locations", []);
  const [loading, setLoading] = useState(false);
  const [loadingSubjects, setLoadingSubjects] = useState(false);
  const [fields, setFields] = useState({
    name: "",
    email: "",
    address: "",
    subjects: [],
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [subjectsOptions, setSubjectsOptions] = useState([]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`/api/locations/get-location/${locationId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        setFields(data);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoadingSubjects(true);

    axios
      .get(`/api/subjects/select-subjects`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        const subjectsOptions = data.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        setSubjectsOptions(subjectsOptions);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingSubjects(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .patch(
          `/api/locations/edit-location/${locationId}`,
          fields,
          getRequestHeaders()
        )
        .then((res) => {
          const { data = {} } = res;
          const { name = "" } = data;
          toast.success(`Locatia [${name}] a fost modificata cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          const {
            name = "",
            email = "",
            address = "",
            subjects = "",
            description = "",
          } = catchHandler(history, err);
          setErrors({ name, email, address, subjects, description });
          setLoading(false);
        });
    },
    [history, fields, rootPathname]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onMultiSelectChange = useCallback((options, action) => {
    const { name = "" } = action || {};
    setFields((prevFields) => ({
      ...prevFields,
      [name]: options.map(({ value }) => value),
    }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Modificare Locatie</h2>
            <Form>
              <FormGroup>
                <Label for="name">Denumire</Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  invalid={Boolean(errors["name"])}
                  value={fields["name"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["name"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  invalid={Boolean(errors["email"])}
                  value={fields["email"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["email"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="address">Adresa</Label>
                <Input
                  id="address"
                  name="address"
                  type="text"
                  invalid={Boolean(errors["address"])}
                  value={fields["address"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["address"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="subjects">Discipline</Label>
                <Select
                  name="subjects"
                  className={Boolean(errors["subjects"]) ? "is-invalid" : ""}
                  placeholder="Alegeti disciplinele"
                  noOptionsMessage={() => <span>Fara rezultat</span>}
                  isClearable={true}
                  isMulti={true}
                  isLoading={loadingSubjects}
                  onChange={onMultiSelectChange}
                  styles={getSelectStyles(Boolean(errors["subjects"]))}
                  value={subjectsOptions.filter(({ value }) =>
                    fields["subjects"].includes(value)
                  )}
                  options={subjectsOptions}
                />
                <FormFeedback>{errors["subjects"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="description">Descriere</Label>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  invalid={Boolean(errors["description"])}
                  value={fields["description"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["description"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading || loadingSubjects}
                >
                  Salvare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default EditLocation;
