import React from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Container,
} from "reactstrap";

class SecondStep extends React.Component {
  state = {
    checked: true,
    open: "",
  };

  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  // Important for Wizard
  isValidated() {
    const { checked } = this.state;
    if (checked) {
      return true;
    } else {
      return false;
    }
  }

  handleChange(event) {
    const { target = {} } = event;
    const { checked = false } = target;
    this.setState({ checked });
  }

  toggle(id) {
    const { open } = this.state;
    if (open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  }

  render() {
    const { open } = this.state;

    return (
      <Row
        style={{
          maxWidth: "100%",
          maxHeight: "calc(100vh - 400px)",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
          overflowX: "hidden",
          overflowY: "auto",
          margin: 0,
        }}
      >
        <Col md={12} className="text-center">
          <h3>Q & A</h3>
        </Col>
        <Col md={12} className="mt-3">
          <Container>
            <Accordion open={open} toggle={this.toggle}>
              <AccordionItem>
                <AccordionHeader targetId="1">
                  1. Sunt eligibil pentru cursurile subvenționate (gratuite)?
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  Dacă ai vârsta cuprinsă între 15 ani împliniţi și 23 de ani
                  neîmpliniți, da, ești eligibil pentru cursurile subvenționate
                  (gratuite) pe care le organizăm - planorism, parasutism si
                  avion ultrausor.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="2">
                  2. Care sunt costurile cursurilor nesubvenționate?
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  Costurile cursurilor pe care le organizăm pot fi găsite pe
                  site-ul nostru:
                  <ul>
                    <li>
                      <a
                        href="http://aeroclubulromaniei.ro/page/sca-cursuri-oferite"
                        target="_blank"
                      >
                        Avion
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://aeroclubulromaniei.ro/page/scau-cursuri-oferite"
                        target="_blank"
                      >
                        Avion ultrausor
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://aeroclubulromaniei.ro/page/scpl-cursuri-oferite"
                        target="_blank"
                      >
                        Planor
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://aeroclubulromaniei.ro/page/scpa-cursuri-oferite"
                        target="_blank"
                      >
                        Parasutism
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://aeroclubulromaniei.ro/page/scb-cursuri-oferite"
                        target="_blank"
                      >
                        Balon cu aer cald
                      </a>
                    </li>
                  </ul>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="3">
                  3. Care este durata cursurilor?
                </AccordionHeader>
                <AccordionBody accordionId="3">
                  Durata cursurilor variază în funcție de disciplină. Modulul
                  teoretic al cursurilor se desfăşoară pe durata a aproximativ 6
                  săptămâni iar la finalul acestuia se susţine un examen scris
                  din materiile studiate. <br />
                  Modulul practic începe după finalizarea celui teoretic şi
                  durata sa este, în general, între 4 şi 12 luni şi variază în
                  funcție de condițiile meteorologice, de disponibilitatea
                  elevului și de abilităţile acestuia.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="4">
                  4. Este posibil să obțin licența într-un singur an?
                </AccordionHeader>
                <AccordionBody accordionId="4">
                  Da, este posibil ca oricare dintre licențe să fie obținute
                  într-un singur an. Pentru aceasta este însă nevoie de o
                  disponibilitate ridicată și de o participare cu frecvenţă
                  foarte bună la activitatea de zbor.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="5">
                  5. Cât durează o zi de zbor?
                </AccordionHeader>
                <AccordionBody accordionId="5">
                  În general o zi de zbor durează aproximativ 8 ore. Dimineața
                  se efectuează briefing-ul dinaintea activității de zbor, după
                  care se efectuează activitatea de zbor în sine, urmând ca la
                  final să urmeze debriefing-ul zilei de zbor.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="6">
                  6. Ce se întâmplă dacă nu pot să particip la cursurile
                  teoretice?
                </AccordionHeader>
                <AccordionBody accordionId="6">
                  Este obligatorie prezenţa în proporție de minim 75% la
                  cursurile teoretice. Nerespectarea acestei cerinţe poate duce
                  la excluderea elevului din curs.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="7">
                  7. Pot să mă înscriu la curs dacă împlinesc vârsta de 23 de
                  ani anul acesta?
                </AccordionHeader>
                <AccordionBody accordionId="7">
                  Da, te poți înscrie la curs. În cazul în care te-ai înscris la
                  curs la vârsta de 22 ani, iar pe parcursul acestuia împlinești
                  23 de ani, după data respectivă cursul poate fi continuat doar
                  contracost. Spre exemplu, dacă ai parcurs 30% din curs până la
                  împlinirea vârstei de 23 de ani, restul poate fi continuat
                  doar achitând contravaloarea părții rămase, respectiv 70%.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="8">
                  8. Pot să mă înscriu la curs dacă am vârsta de 15 ani?
                </AccordionHeader>
                <AccordionBody accordionId="8">
                  Da, dacă ai împlinit vârsta de 15 ani te poți înscrie la
                  cursurile noastre și poți parcurge modulul teoretic însă nu
                  vei putea participa la activitatea de zbor decât după
                  împlinirea vârstei de 16 ani. <br />
                  <strong>
                    Pentru admiterea la cursul de pregătire în vederea obținerii
                    licenței de pilot planor vârsta minimă este de 15 ani în
                    anul începerii pregătirii teoretice.
                  </strong>
                  <br />
                  Cursantul poate începe activitatea de zbor, dar va putea
                  efectua zboruri în simplă comandă doar după împlinirea vârstei
                  de 16 ani. De aceea, recomandăm să te înscrii la curs în anul
                  în care vei putea efectua și activitate de zbor, deoarece
                  pauzele mari dintre modulul teoretic și cel practic nu
                  favorizează progresul.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="9">
                  9. Cum se va desfășura cursul teoretic?
                </AccordionHeader>
                <AccordionBody accordionId="9">
                  Cursul teoretic pentru cursurile subvenționate (gratuite) este
                  format din două module: primul se va desfășura în sistem
                  online, fiind urmat de o examinare intermediară cu participare
                  fizică. După această examinare, al doilea modul al cursului se
                  va desfășura exclusiv fizic. Cursurile teoretice pentru ambele
                  module se vor desfășura cu preponderență în zilele de sâmbătă
                  și duminică, maxim 6 ore pe zi. Fiecare locație şi fiecare
                  curs va avea un program distinct iar acesta va fi comunicat în
                  timp util, după finalizarea înscrierilor. Criteriul privind
                  prezența minimă obligatorie rămâne, desigur, valabil.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="10">
                  10. În ce constă admiterea la zbor (modulul practic al
                  cursului)?
                </AccordionHeader>
                <AccordionBody accordionId="10">
                  Admiterea la zbor constă în examene scrise din materiile
                  studiate la modulul teoretic. Nota minimă pentru promovare
                  este de 75% la fiecare materie iar locurile subvenţionate vor
                  fi ocupate în ordinea mediilor notelor obţinute. Suplimentar,
                  participarea la modulul practic al cursului pentru obţinerea
                  licenţei de paraşutist va fi condiţionată de o testare a
                  aptitudinilor sportive.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="11">
                  11. Ce documente sunt necesare?
                </AccordionHeader>
                <AccordionBody accordionId="11">
                  Pentru fiecare cursant va fi întocmit un dosar personal care
                  va fi completat în etape.
                  <br />
                  Înscrierea pentru cursuri se efectuează pe o platformă
                  dedicată care va fi disponibilă pe pagina de web a
                  Aeroclubului României. Aveți nevoie de o copie a documentului
                  de identitate şi să achitați taxa de legitimare la Aeroclubul
                  României în valoare de 15 euro. Candidaţii minori vor trebui
                  să depună şi consimţământul notarial al părintelui sau al
                  tutorelui legal. O copie a documentului de identitate este de
                  asemenea necesară.
                  <br />
                  Candidaţii pentru disciplina paraşutism vor trebui ca înainte
                  de susţinerea testării aptitudinilor sportive să depună o
                  adeverinţă de la medicul de familie care să ateste că sunt
                  apţi pentru a desfăşura activităţi de pregătire.
                  <br />
                  După examenul de finalizare a cursurilor teoretice, cei
                  declaraţi admişi, vor completa dosarul personal cu
                  certificatul medical aeronautic clasa a II-a și vor fi
                  informaţi de către instructorii aeroclubului la care au ales
                  să îşi desfăşoare activitatea asupra modului în care se va
                  desfăşura activitatea pe aerodrom.
                  <br />
                  După finalizarea modului practic (în zbor / salt cu paraşuta)
                  de pregătire va fi emis un certificat de absolvire şi
                  cursantul va fi planificat în vederea susţinerii examinării
                  pentru obţinerea licenţei cu autoritatea competentă.
                  <br />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="12">
                  12. Ce costuri există pentru cursurile subvenționate
                  (gratuite)?
                </AccordionHeader>
                <AccordionBody accordionId="12">
                  Pentru cursurile subvenționate (gratuite), costul este
                  contravaloarea sumei de 15 EUR reprezentând taxa de legitimare
                  la Aeroclubului României. Cursantul va mai trebui să suporte
                  costul obţinerii certificatului de aptitudine medicală în
                  valoare de aproximativ 50 EUR. Acesta se achită la unitatea
                  medicală autorizată care emite certificatul.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="13">
                  13. Există mai multe perioade de înscriere de-a lungul unui
                  an?
                </AccordionHeader>
                <AccordionBody accordionId="13">
                  Pentru cursurile subvenționate (gratuite), Aeroclubul României
                  face înscrierile o singură data pe an în a doua jumătate a
                  lunii ianuarie şi prima parte a lunii februarie. <br />
                  Pentru cursurile contracost, în funcție de solicitări, pot
                  exista mai multe perioade, motiv pentru care vă sugerăm ca,
                  dacă sunteţi interesaţi de un astfel de curs, să luaţi din
                  timp legătura cu unitatea teritorială unde doriţi să îl
                  efectuaţi.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="14">
                  14. Ce criteriu de ocupare a locurilor subvenționate
                  (gratuite) există?
                </AccordionHeader>
                <AccordionBody accordionId="14">
                  Criteriul pentru ocuparea locurilor subvenționate este media
                  notelor obținute la examenul de finalizare a modului teoretic
                  al cursului respectiv.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="15">
                  15. Unde achit cotizația anuală de membru al Aeroclubului
                  României?
                </AccordionHeader>
                <AccordionBody accordionId="15">
                  Cotizația se achită pe platforma&nbsp;
                  <a href="https://legitimare.ar.ro" target="_blank">
                    https://legitimare.ar.ro
                  </a>
                  , reprezentând și pasul final ce trebuie urmat pentru
                  finalizarea înscrierii.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="16">
                  16. Ce pot face după ce obțin licența în cadrul Aeroclubului
                  României?
                </AccordionHeader>
                <AccordionBody accordionId="16">
                  După obţinerea licenţei tinerii care prezintă aptitudini pot
                  fi selecţionaţi în loturile Aeroclubului României, unde îşi
                  pot completa şi desăvârşi pregătirea ca sportivi, pot
                  participa la competiţii şi demonstraţii aeronautice. Aceasta
                  poate constitui şi fundamentul unei viitoare cariere
                  profesionale în aviaţie, posibil chiar în cadrul Aeroclubului
                  României, având în vedere că cea mai mare parte dintre
                  instructorii de zbor ai organizaţiei noastre provin din rândul
                  foştilor cursanţi.
                  <br /> Pregătirea în cadrul loturilor judeţene se efectuează
                  în etape, este direcţionată către obţinerea de performanţe în
                  sporturile aeronautice şi este supusă unui proces continuu de
                  selecţie în urma căruia tinerii pot ajunge să reprezinte
                  România la competiţiile interne şi internaţionale.
                  <br /> Sportivii care prezintă aptitudini, participă la
                  pregătire şi obţin rezultate la concursuri pot fi selecţionaţi
                  pentru acordarea de programe de pregătire subvenţionate în
                  vederea obţinerii de licenţe şi calificări suplimentare
                  (PPL(A), acrobaţie, etc.).
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="17">
                  17. Care dintre discipline sunt subvenționate (gratuite)?
                </AccordionHeader>
                <AccordionBody accordionId="17">
                  Disciplinele subvenționate (gratuite) sunt: avion ultraușor,
                  planorism și parașutism. Fiecare Aeroclub Teritorial
                  organizează cursuri subvenționate pentru una sau mai multe
                  discipline.
                  <br />
                  <a
                    href="https://aeroclubulromaniei.ro/articol/inscrieri-cursuri-2024"
                    target="_blank"
                  >
                    Mai multe informații aici!
                  </a>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="18">
                  18. Pot să mă înscriu la mai multe discipline în același an?
                </AccordionHeader>
                <AccordionBody accordionId="18">
                  Pentru că pregătirea teoretică este specifică fiecărei
                  discipline și acestea sunt organizate în același interval
                  orar, nu puteți îndeplini cerința de prezență minimă
                  obligatorie. (75%). În cazul în care v-ați înscris deja la o
                  disciplină și doriți să vă schimbați decizia, contactați
                  aeroclubul teritorial pentru a solicita retragerea inițială a
                  dosarului de înscriere.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="19">
                  19. Care este vârsta maximă pentru cursurile contracost?
                </AccordionHeader>
                <AccordionBody accordionId="19">
                  Nu există o vârstă maximă pentru cursurile contracost.
                  Condiția este ca elevul să dețină un certificat medical în
                  termen de valabilitate obținut într-o unitate medicală
                  autorizată.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="20">
                  20. Unde pot efectua vizita medicală?
                </AccordionHeader>
                <AccordionBody accordionId="20">
                  Pentru a participa la cursurile noastre este nevoie de
                  certificate medical minim clasa a 2-a. Acesta se poate obține
                  doar de la una dintre unităţile medicale autorizate de pe
                  lista publicată pe site-ul&nbsp;
                  <a
                    href="https://www.caa.ro/uploads/pages/Lista%20AME.pdf"
                    target="_blank"
                  >
                    Autorității Aeronautice Civile Române
                  </a>
                  .
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="21">
                  21. Este necesar vreun echipament special?
                </AccordionHeader>
                <AccordionBody accordionId="21">
                  Nu, nu este nevoie de echipament deosebit.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="22">
                  22. Ce se întâmplă dacă în urma examenului nu mă încadrez pe
                  unul dintre locurile subvenţionate?
                </AccordionHeader>
                <AccordionBody accordionId="22">
                  Poţi continua cursul efectuând modulul practic contra cost sau
                  te poţi înscrie din nou în anul următor.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="23">
                  23. Ce se întâmplă dacă dintr-un motiv oarecare nu pot
                  finaliza cursul? Am vreo obligaţie dacă mă retrag?
                </AccordionHeader>
                <AccordionBody accordionId="23">
                  Sigur că nu ne dorim o astfel de situaţie dar te poţi retrage
                  din curs oricând fără nicio obligaţie.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="24">
                  24. Mă pot înscrie la curs dacă nu sunt cetăţean român?
                </AccordionHeader>
                <AccordionBody accordionId="24">
                  Da, te poţi înscrie la oricare dintre cursuri dar pentru putea
                  beneficia de subvenţie este necesară cetăţenia română.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="25">
                  25. Când am nevoie de certificatul de aptitudine medicală?
                </AccordionHeader>
                <AccordionBody accordionId="25">
                  Este nevoie de certificatul de aptitudine medicală înainte de
                  începerea modulului practic al cursului.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="26">
                  26. Mă pot înscrie la cursuri dacă port ochelari de vedere
                  (sau am alte probleme legate de sănătate)?
                </AccordionHeader>
                <AccordionBody accordionId="26">
                  Piloţii nu trebuie neapărat să aibă o sănătate perfectă, ci să
                  se încadreze în anumite bareme medicale. Totuşi, dacă ştiţi că
                  aveţi unele probleme de sănătate ar fi bine să vă adresaţi
                  unui centru medical autorizat. Lista acestora şi datele de
                  contact pot fi găsite pe site-ul Autorităţii Aeronautice
                  Civile Române.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Container>
        </Col>
        {/* <Col md={12} className="text-center mt-3">
          <FormGroup check inline>
            <Input
              id="accept"
              name="accept"
              type="checkbox"
              checked={checked}
              onChange={this.handleChange}
            />
            <Label for="accept" check>
              Accept <strong>Termenii si conditiile</strong>
            </Label>
          </FormGroup>
        </Col> */}
      </Row>
    );
  }
}

export default SecondStep;
