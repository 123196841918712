// Packages
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
import UserMetadataForm from "../components/forms/user-metadata";
// Utils
import {
  catchHandler,
  getRequestHeaders,
  userRoleOptions,
  getSelectStyles,
  userRoles,
  userRoleLegitimatOption,
} from "../utils";
import { validationSchemaUserMetadata } from "../components/forms/schemas/user-metadata";
// Styling
import "./EditUser.css";

const EditUser = React.memo((props) => {
  const { id: userId } = useParams();
  const rootPathname = useMemo(() => "/users-management", []);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [userMetadata, setUserMetadata] = useState(null);
  const [fields, setFields] = useState({
    email: "",
    role: "",
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const isLeg = useMemo(
    () => fields.role === userRoles.LEGITIMAT,
    [fields.role]
  );
  const formRef = useRef(null);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`/api/users/get-user/${userId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { metadata = {}, email, role } = data;
        setUserMetadata(metadata);
        setFields({ email, role });
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (isLeg) {
        setValidated(true);

        formRef?.current?.validateForm();
        validationSchemaUserMetadata.validateSync(formRef?.current?.values);
      }

      setLoading(true);
      setErrors({});

      axios
        .patch(
          `/api/users/edit-user/${userId}`,
          Object.assign(
            { ...fields },
            isLeg && { metadata: formRef?.current?.values }
          ),
          getRequestHeaders()
        )
        .then((res) => {
          const { data = {} } = res;
          const { email = "" } = data;
          toast.success(`Utilizatorul [${email}] a fost modificat cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          const { role = "" } = catchHandler(history, err);
          setErrors({ role });
          setLoading(false);
        });
    },
    [formRef, isLeg, history, fields, rootPathname]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onSelectChange = useCallback((option, action) => {
    const { value = "" } = option || {};
    const { name = "" } = action || {};
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" lg={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Modificare Utilizator</h2>
            {isLeg ? (
              <UserMetadataForm
                formRef={formRef}
                initialValues={userMetadata}
                validated={validated}
                disabledPrimaryFields
              >
                <FormGroup className="d-flex justify-content-between mt-5">
                  <Button
                    type="submit"
                    color="primary"
                    className="right"
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    Salvare
                  </Button>
                  <Button outline color="secondary" onClick={onCancel}>
                    Renuntare
                  </Button>
                </FormGroup>
              </UserMetadataForm>
            ) : (
              <Form>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    value={fields["email"]}
                    disabled={true}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="role">Rol</Label>
                  <Select
                    name="role"
                    className={Boolean(errors["role"]) ? "is-invalid" : ""}
                    placeholder="Alegeti un rol"
                    noOptionsMessage={() => <span>Fara rezultat</span>}
                    isClearable={true}
                    onChange={onSelectChange}
                    styles={getSelectStyles(Boolean(errors["role"]))}
                    value={
                      fields["role"] === userRoles.LEGITIMAT
                        ? userRoleLegitimatOption
                        : fields["role"]
                        ? userRoleOptions[fields["role"]]
                        : null
                    }
                    options={
                      fields["role"] === userRoles.LEGITIMAT
                        ? [userRoleLegitimatOption]
                        : Object.values(userRoleOptions)
                    }
                    isDisabled={fields["role"] === userRoles.LEGITIMAT}
                  />
                  <FormFeedback>{errors["role"]}</FormFeedback>
                </FormGroup>
                <FormGroup className="d-flex justify-content-between mt-5">
                  <Button
                    type="submit"
                    color="primary"
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    Salvare
                  </Button>
                  <Button outline color="secondary" onClick={onCancel}>
                    Renuntare
                  </Button>
                </FormGroup>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default EditUser;
