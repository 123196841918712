// Packages
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { BsFillShieldLockFill, BsAirplaneEnginesFill } from "react-icons/bs";
import {
  formatDateToDDMMYYYY,
  getRequestHeaders,
  catchHandler,
} from "../utils";

// Styling
import "./Landing.css";

const Landing = React.memo((props) => {
  const [agreementLimit, setAgreementLimit] = useState(new Date());
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`/api/wizard/get-agreement`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { limit } = data;
        setAgreementLimit(new Date(limit));
      })
      .catch(catchHandler);
  }, []);

  const onLoginClick = useCallback(() => {
    history.push("/login");
  }, [history]);

  const onWizardClick = useCallback(() => {
    history.push("/wizard");
  }, [history]);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Container className="p-3">
            <h1 className="text-center pb-3">Legitimare AR</h1>
            <p className="px-5">
              <strong>Daca ai sub 18 ani</strong>, vei avea nevoie de un acord
              notarial legalizat si semnat de parinti (consimțământ).
              <br />
              Fara acest acord nu vei putea participa la cursurile teoretice si
              practice.
              <br />
              Ai{" "}
              <a
                href="https://docs.google.com/document/d/1rJ0AFBwvBEiBb9PPDA6c6gaLQQY5G9ok/edit?usp=sharing&ouid=116591481093533213143&rtpof=true&sd=true"
                target="_blank"
              >
                aici
              </a>{" "}
              un model de acord notarial.
              <br />
              Acest acord trebuie legalizat la un notariat autorizat.
              <br />
              Trimite-l in original prin curier{" "}
              <strong>
                pana la data de {formatDateToDDMMYYYY(agreementLimit)}
              </strong>{" "}
              la Aeroclubul Teritorial unde te-ai inscris.
              <br />
              Lista cu adresele Aerocluburilor Teritoriale o gasesti&nbsp;
              <a
                href="https://docs.google.com/document/d/1y1Ekt-GBqU1b-5z1QzlWkRX6hdDT4UKS/edit?usp=sharing&ouid=116591481093533213143&rtpof=true&sd=true "
                target="_blank"
              >
                aici
              </a>
              .
            </p>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col md={2}></Col>
        <Col md={4}>
          <Card
            className="landing-card"
            style={{
              width: "18rem",
              margin: "auto",
            }}
          >
            <Button outline onClick={onWizardClick}>
              <CardBody>
                <CardTitle tag="h1">Inscriere</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Membru nou
                </CardSubtitle>
              </CardBody>
              <BsAirplaneEnginesFill className="landing-card-icon" />
              <CardBody>
                <CardText>
                  Pentru inscrierea in platforma asigurati-va ca aveti pregatita
                  o <strong>copie digitala a cartii de identitate</strong>.
                </CardText>
              </CardBody>
            </Button>
          </Card>
        </Col>
        <Col md={4}>
          <Card
            className="text-center"
            style={{
              width: "18rem",
              margin: "auto",
            }}
          >
            <Button outline onClick={onLoginClick}>
              <CardBody>
                <CardTitle tag="h1">Login</CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  Utilizator existent
                </CardSubtitle>
              </CardBody>
              <BsFillShieldLockFill className="landing-card-icon" />
              <CardBody>
                <CardText>
                  Utilizatori existenti care vor sa faca plati, sa se inscrie la
                  cursuri sau sa isi schimbe detaliile personale.
                </CardText>
              </CardBody>
            </Button>
          </Card>
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
});

export default Landing;
