import React from "react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { FormGroup, FormFeedback, Input, Row, Col } from "reactstrap";
import {
  catchHandler,
  entriesToOptions,
  getRequestHeaders,
  getSelectStyles,
} from "../../utils";
let i = 0;
class FourthStep extends React.Component {
  state = {
    optionsLocatie: [],
    optionsDisciplina: [],
    optionsCurs: [],
    Locatie: "",
    Disciplina: "",
    Curs: "",
    Files: "",
    LocatieTouched: false,
    CursTouched: false,
    FilesTouched: false,
    UploadingFile: false,
    fileRef: "",
    fileError: "",
    isSubventionat: false,
    loading: false,
  };

  constructor(props) {
    super(props);
    this.isValidated = this.isValidated.bind(this);
    this.onLocationsSelectChange = this.onLocationsSelectChange.bind(this);
    this.onSubjectsSelectChange = this.onSubjectsSelectChange.bind(this);
    this.onCoursesSelectChange = this.onCoursesSelectChange.bind(this);
    this.onFilesInput = this.onFilesInput.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
      isSubventionat: Boolean(sessionStorage.getItem("isSubventionat")),
    });

    axios
      .get(`/api/wizard/select-locations`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        this.setState({
          optionsLocatie: data.map(entriesToOptions),
        });
      })
      .catch(catchHandler)
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { isSubventionat, Locatie, Disciplina, optionsDisciplina } =
      nextState;
    const currentIsSubventionatStorage = Boolean(
      sessionStorage.getItem("isSubventionat")
    );
    if (isSubventionat !== currentIsSubventionatStorage) {
      this.setState({
        loading: true,
        isSubventionat: currentIsSubventionatStorage,
      });

      if (
        Locatie &&
        Disciplina &&
        optionsDisciplina.find(({ value }) => value === Disciplina)
          ?.deactivatedForSubventionat &&
        currentIsSubventionatStorage
      ) {
        this.setState({ ["Disciplina"]: "" });
        this.setState({ ["Curs"]: "", optionsCurs: [] });
      }

      axios
        .get(
          `/api/wizard/select-subjects/${Locatie}?subventionat=${currentIsSubventionatStorage}`,
          getRequestHeaders()
        )
        .then((res) => {
          const { data = [] } = res;
          this.setState({
            optionsDisciplina: data.map((entry) =>
              entriesToOptions(entry, { extra: "deactivatedForSubventionat" })
            ),
          });
        })
        .catch(catchHandler)
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  // Important for Wizard
  isValidated() {
    this.setState({
      LocatieTouched: true,
      DisciplinaTouched: true,
      CursTouched: true,
      FilesTouched: true,
    });
    return Boolean(
      this.state.Locatie &&
        this.state.Disciplina &&
        this.state.Curs &&
        this.state.fileRef
    );
  }

  onLocationsSelectChange(option) {
    const { value = "" } = option || {};
    const { isSubventionat } = this.state;

    if (!value) {
      this.setState({
        optionsDisciplina: [],
      });
      this.setState({
        optionsCurs: [],
      });
    } else {
      this.setState({ loading: true });

      axios
        .get(
          `/api/wizard/select-subjects/${value}?subventionat=${isSubventionat}`,
          getRequestHeaders()
        )
        .then((res) => {
          const { data = [] } = res;
          this.setState({
            optionsDisciplina: data.map((entry) =>
              entriesToOptions(entry, { extra: "deactivatedForSubventionat" })
            ),
          });
        })
        .catch(catchHandler)
        .finally(() => {
          this.setState({ loading: false });
        });
    }

    this.setState({ ["Locatie"]: value, ["LocatieTouched"]: true });
    this.setState({ ["Disciplina"]: "" });
    this.setState({ ["Curs"]: "", optionsCurs: [] });
  }

  onSubjectsSelectChange(option) {
    const { value = "" } = option || {};

    if (!value) {
      this.setState({
        optionsCurs: [],
      });
    } else {
      this.setState({ loading: true });

      axios
        .get(`/api/wizard/select-courses/${value}`, getRequestHeaders())
        .then((res) => {
          const { data = [] } = res;
          this.setState({
            optionsCurs: data.map(entriesToOptions),
          });
        })
        .catch(catchHandler)
        .finally(() => {
          this.setState({ loading: false });
        });
    }

    this.setState({ ["Disciplina"]: value, ["DisciplinaTouched"]: true });
    this.setState({ ["Curs"]: "" });
  }

  onCoursesSelectChange(option) {
    const { value = "" } = option || {};
    this.setState({ ["Curs"]: value, ["CursTouched"]: true });
  }

  onFilesInput(event) {
    const { target = {} } = event;
    const { files } = target;

    this.setState({ ["FilesTouched"]: true });

    if (!files?.length) {
      this.setState({ fileRef: "" });
      return;
    }

    // verify files
    for (let file of files) {
      const { type } = file;
      // pdf|jpeg|jpg|png limit - sync with server
      if (!type.startsWith("image/") && type !== "application/pdf") {
        this.setState({
          fileError: "Poti incarca doar fisiere PDF sau Imagini",
        });
        return;
      }

      // 20Mb limit - sync with server
      const maxFileSizeInMB = 20;
      const maxFileSizeInB = 1024 * 1024 * maxFileSizeInMB;

      if (file.size > maxFileSizeInB) {
        this.setState({
          fileError: "Poti incarca doar de maxim 20Mb",
        });
        return;
      }
    }

    const formData = new FormData();
    for (let file of files) {
      formData.append("files", file);
    }

    this.setState({ ["UploadingFile"]: true });
    axios
      .post(
        "/api/public-files/create-file",
        formData,
        getRequestHeaders({ requestHasFilesFlag: true })
      )
      .then(({ data }) => {
        const { fileRef = "" } = data || {};
        this.setState({ fileRef, fileError: "" });
        toast.success("Documentul a fost salvat cu succes");
      })
      .catch((err) => {
        this.setState({ fileRef: "", fileError: "Fisierul nu este valid" });
        catchHandler(null, err);
      })
      .finally(() => {
        this.setState({ ["UploadingFile"]: false });
      });
  }

  render() {
    const {
      optionsLocatie,
      optionsDisciplina,
      optionsCurs,
      Locatie,
      Disciplina,
      Curs,
      Files,
      LocatieTouched,
      DisciplinaTouched,
      CursTouched,
      FilesTouched,
      UploadingFile,
      fileError,
      fileRef,
    } = this.state;

    return (
      <Row>
        <Col md={4}></Col>
        <Col md={4}>
          <h5>Alege unde doresti sa te inscrii</h5>
          <h6>
            Daca nu gasesti tipul de licenta dorit in locatia selectata de tine,
            aceasta disciplina nu este disponibila in aceasta locatie. Incearca
            o alta locatie apropiata.
          </h6>
          <FormGroup className="mt-3">
            <Select
              name="Locatie"
              className={
                Boolean(LocatieTouched && !Locatie) ? "is-invalid" : ""
              }
              placeholder="Locatie"
              noOptionsMessage={() => <span>Fara rezultat</span>}
              isClearable={true}
              onChange={this.onLocationsSelectChange}
              styles={getSelectStyles(Boolean(LocatieTouched && !Locatie))}
              value={
                optionsLocatie.find(({ value }) => value === Locatie) || null
              }
              options={optionsLocatie}
            />
            <FormFeedback>
              {LocatieTouched && !Locatie && "Camp obligatoriu"}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Select
              name="Disciplina"
              className={
                Boolean(DisciplinaTouched && !Disciplina) ? "is-invalid" : ""
              }
              placeholder="Disciplina"
              noOptionsMessage={() =>
                !Locatie ? (
                  <span>Selectati o locatie</span>
                ) : (
                  <span>Fara rezultat</span>
                )
              }
              isClearable={true}
              onChange={this.onSubjectsSelectChange}
              styles={getSelectStyles(
                Boolean(DisciplinaTouched && !Disciplina)
              )}
              value={
                optionsDisciplina.find(({ value }) => value === Disciplina) ||
                null
              }
              options={optionsDisciplina}
            />
            <FormFeedback>
              {DisciplinaTouched && !Disciplina && "Camp obligatoriu"}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Select
              name="Curs"
              className={Boolean(CursTouched && !Curs) ? "is-invalid" : ""}
              placeholder="Curs"
              noOptionsMessage={() =>
                !Disciplina ? (
                  <span>Selectati o disciplina</span>
                ) : (
                  <span>Fara rezultat</span>
                )
              }
              isClearable={true}
              onChange={this.onCoursesSelectChange}
              styles={getSelectStyles(Boolean(CursTouched && !Curs))}
              value={optionsCurs.find(({ value }) => value === Curs) || null}
              options={optionsCurs}
            />
            <FormFeedback>
              {CursTouched && !Curs && "Camp obligatoriu"}
            </FormFeedback>
          </FormGroup>
          <h5>Carte de identitate</h5>
          <FormGroup>
            <Input
              type="file"
              name="files"
              accept="application/pdf"
              className={
                Boolean(FilesTouched && (fileError || (!Files && !fileRef)))
                  ? "is-invalid"
                  : ""
              }
              styles={getSelectStyles(
                Boolean(FilesTouched && (fileError || (!Files && !fileRef)))
              )}
              onChange={this.onFilesInput}
              disabled={UploadingFile}
            />
            <FormFeedback>
              {FilesTouched &&
                (fileError || (!Files && !fileRef && "Camp obligatoriu"))}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default FourthStep;
