// Packages
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
// Pages
import NotFound from "../pages/NotFound";
// Utils
import { isAuthenticated, pageTitleBase } from "../utils";

const ExclusivePublicRoute = React.memo(
  ({ component: Component, pageTitle = "", ...rest }) => {
    useEffect(() => {
      document.title = pageTitle
        ? `${pageTitleBase} - ${pageTitle}`
        : pageTitleBase;
    }, [pageTitle]);

    return (
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated() ? <Component {...props} /> : <NotFound />
        }
      />
    );
  }
);

export default ExclusivePublicRoute;
