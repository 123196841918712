import React from "react";
import { Formik } from "formik";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import { defaultValuesUserMetadata, validationSchemaUserMetadata } from "./schemas/user-metadata";

const UserMetadataForm = React.memo((props) => {
  const { children, formRef, initialValues, validated, disabledPrimaryFields } = props;

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues || defaultValuesUserMetadata}
        validationSchema={validationSchemaUserMetadata}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Row>
              <Col md={12}>
                <h5>Date personale</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="Nume"
                    name="Nume"
                    placeholder="Nume*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["Nume"]) && errors["Nume"]
                    )}
                    value={values["Nume"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="Nume">Nume*</Label>
                  <FormFeedback>
                    {(validated || touched["Nume"]) && errors["Nume"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="Prenume"
                    name="Prenume"
                    placeholder="Prenume*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["Prenume"]) && errors["Prenume"]
                    )}
                    value={values["Prenume"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="Prenume">Prenume*</Label>
                  <FormFeedback>
                    {(validated || touched["Prenume"]) && errors["Prenume"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup floating>
                  <Input
                    id="CNP"
                    name="CNP"
                    placeholder="CNP*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["CNP"]) && errors["CNP"]
                    )}
                    value={values["CNP"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabledPrimaryFields}
                  />
                  <Label for="CNP">CNP*</Label>
                  <FormFeedback>
                    {(validated || touched["CNP"]) && errors["CNP"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="Serie"
                    name="Serie"
                    placeholder="Serie CI*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["Serie"]) && errors["Serie"]
                    )}
                    value={values["Serie"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="Serie">Serie CI*</Label>
                  <FormFeedback>
                    {(validated || touched["Serie"]) && errors["Serie"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="Numar"
                    name="Numar"
                    placeholder="Numar CI*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["Numar"]) && errors["Numar"]
                    )}
                    value={values["Numar"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="Numar">Numar CI*</Label>
                  <FormFeedback>
                    {(validated || touched["Numar"]) && errors["Numar"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="Inaltime"
                    name="Inaltime"
                    placeholder="Inaltime (cm)*"
                    type="number"
                    min={0}
                    max={300}
                    invalid={Boolean(
                      (validated || touched["Inaltime"]) && errors["Inaltime"]
                    )}
                    value={values["Inaltime"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="Inaltime">Inaltime (cm)*</Label>
                  <FormFeedback>
                    {(validated || touched["Inaltime"]) && errors["Inaltime"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="Greutate"
                    name="Greutate"
                    placeholder="Greutate (kg)*"
                    type="number"
                    min={0}
                    max={300}
                    invalid={Boolean(
                      (validated || touched["Greutate"]) && errors["Greutate"]
                    )}
                    value={values["Greutate"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="Greutate">Greutate (kg)*</Label>
                  <FormFeedback>
                    {(validated || touched["Greutate"]) && errors["Greutate"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="Email"
                    name="Email"
                    placeholder="Email*"
                    type="email"
                    invalid={Boolean(
                      (validated || touched["Email"]) && errors["Email"]
                    )}
                    value={values["Email"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabledPrimaryFields}
                  />
                  <Label for="Email">Email*</Label>
                  <FormFeedback>
                    {(validated || touched["Email"]) && errors["Email"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="Telefon"
                    name="Telefon"
                    placeholder="Telefon*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["Telefon"]) && errors["Telefon"]
                    )}
                    value={values["Telefon"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="Telefon">Telefon*</Label>
                  <FormFeedback>
                    {(validated || touched["Telefon"]) && errors["Telefon"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h5>Adresa Domiciliu</h5>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup floating>
                  <Input
                    id="StradaDomiciliu"
                    name="StradaDomiciliu"
                    placeholder="Strada*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["StradaDomiciliu"]) &&
                        errors["StradaDomiciliu"]
                    )}
                    value={values["StradaDomiciliu"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="StradaDomiciliu">Strada*</Label>
                  <FormFeedback>
                    {(validated || touched["StradaDomiciliu"]) &&
                      errors["StradaDomiciliu"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="NumarDomiciliu"
                    name="NumarDomiciliu"
                    placeholder="Numar*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["NumarDomiciliu"]) &&
                        errors["NumarDomiciliu"]
                    )}
                    value={values["NumarDomiciliu"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="NumarDomiciliu">Numar*</Label>
                  <FormFeedback>
                    {(validated || touched["NumarDomiciliu"]) &&
                      errors["NumarDomiciliu"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="BlocDomiciliu"
                    name="BlocDomiciliu"
                    placeholder="Bloc"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["BlocDomiciliu"]) &&
                        errors["BlocDomiciliu"]
                    )}
                    value={values["BlocDomiciliu"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="BlocDomiciliu">Bloc</Label>
                  <FormFeedback>
                    {(validated || touched["BlocDomiciliu"]) &&
                      errors["BlocDomiciliu"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="ScaraDomiciliu"
                    name="ScaraDomiciliu"
                    placeholder="Scara"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["ScaraDomiciliu"]) &&
                        errors["ScaraDomiciliu"]
                    )}
                    value={values["ScaraDomiciliu"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="ScaraDomiciliu">Scara</Label>
                  <FormFeedback>
                    {(validated || touched["ScaraDomiciliu"]) &&
                      errors["ScaraDomiciliu"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup floating>
                  <Input
                    id="ApartamentDomiciliu"
                    name="ApartamentDomiciliu"
                    placeholder="Apartament"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["ApartamentDomiciliu"]) &&
                        errors["ApartamentDomiciliu"]
                    )}
                    value={values["ApartamentDomiciliu"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="ApartamentDomiciliu">Apartament</Label>
                  <FormFeedback>
                    {(validated || touched["ApartamentDomiciliu"]) &&
                      errors["ApartamentDomiciliu"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="LocalitateDomiciliu"
                    name="LocalitateDomiciliu"
                    placeholder="Localitate*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["LocalitateDomiciliu"]) &&
                        errors["LocalitateDomiciliu"]
                    )}
                    value={values["LocalitateDomiciliu"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="LocalitateDomiciliu">Localitate*</Label>
                  <FormFeedback>
                    {(validated || touched["LocalitateDomiciliu"]) &&
                      errors["LocalitateDomiciliu"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="JudetDomiciliu"
                    name="JudetDomiciliu"
                    placeholder="Judet*"
                    type="text"
                    invalid={Boolean(
                      (validated || touched["JudetDomiciliu"]) &&
                        errors["JudetDomiciliu"]
                    )}
                    value={values["JudetDomiciliu"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="JudetDomiciliu">Judet*</Label>
                  <FormFeedback>
                    {(validated || touched["JudetDomiciliu"]) &&
                      errors["JudetDomiciliu"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <h5>Adresa Resedinta</h5>
              </Col>
              <Col md={8}>
                <FormGroup check inline>
                  <Input
                    id="AceeasiAdresa"
                    name="AceeasiAdresa"
                    type="checkbox"
                    checked={values["AceeasiAdresa"]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Label for="AceeasiAdresa" check>
                    Aceeasi adresa ca cea de domiciliu
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            {!values.AceeasiAdresa && (
              <>
                <Row>
                  <Col md={4}>
                    <FormGroup floating>
                      <Input
                        id="StradaResedinta"
                        name="StradaResedinta"
                        placeholder="Strada*"
                        type="text"
                        invalid={Boolean(
                          (validated || touched["StradaResedinta"]) &&
                            errors["StradaResedinta"]
                        )}
                        value={values["StradaResedinta"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label for="StradaResedinta">Strada*</Label>
                      <FormFeedback>
                        {(validated || touched["StradaResedinta"]) &&
                          errors["StradaResedinta"]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup floating>
                      <Input
                        id="NumarResedinta"
                        name="NumarResedinta"
                        placeholder="Numar*"
                        type="text"
                        invalid={Boolean(
                          (validated || touched["NumarResedinta"]) &&
                            errors["NumarResedinta"]
                        )}
                        value={values["NumarResedinta"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label for="NumarResedinta">Numar*</Label>
                      <FormFeedback>
                        {(validated || touched["NumarResedinta"]) &&
                          errors["NumarResedinta"]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup floating>
                      <Input
                        id="BlocResedinta"
                        name="BlocResedinta"
                        placeholder="Bloc"
                        type="text"
                        invalid={Boolean(
                          (validated || touched["BlocResedinta"]) &&
                            errors["BlocResedinta"]
                        )}
                        value={values["BlocResedinta"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label for="BlocResedinta">Bloc</Label>
                      <FormFeedback>
                        {(validated || touched["BlocResedinta"]) &&
                          errors["BlocResedinta"]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup floating>
                      <Input
                        id="ScaraResedinta"
                        name="ScaraResedinta"
                        placeholder="Scara"
                        type="text"
                        invalid={Boolean(
                          (validated || touched["ScaraResedinta"]) &&
                            errors["ScaraResedinta"]
                        )}
                        value={values["ScaraResedinta"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label for="ScaraResedinta">Scara</Label>
                      <FormFeedback>
                        {(validated || touched["ScaraResedinta"]) &&
                          errors["ScaraResedinta"]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup floating>
                      <Input
                        id="ApartamentResedinta"
                        name="ApartamentResedinta"
                        placeholder="Apartament"
                        type="text"
                        invalid={Boolean(
                          (validated || touched["ApartamentResedinta"]) &&
                            errors["ApartamentResedinta"]
                        )}
                        value={values["ApartamentResedinta"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label for="ApartamentResedinta">Apartament</Label>
                      <FormFeedback>
                        {(validated || touched["ApartamentResedinta"]) &&
                          errors["ApartamentResedinta"]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input
                        id="LocalitateResedinta"
                        name="LocalitateResedinta"
                        placeholder="Localitate*"
                        type="text"
                        invalid={Boolean(
                          (validated || touched["LocalitateResedinta"]) &&
                            errors["LocalitateResedinta"]
                        )}
                        value={values["LocalitateResedinta"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label for="LocalitateResedinta">Localitate*</Label>
                      <FormFeedback>
                        {(validated || touched["LocalitateResedinta"]) &&
                          errors["LocalitateResedinta"]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input
                        id="JudetResedinta"
                        name="JudetResedinta"
                        placeholder="Judet*"
                        type="text"
                        invalid={Boolean(
                          (validated || touched["JudetResedinta"]) &&
                            errors["JudetResedinta"]
                        )}
                        value={values["JudetResedinta"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Label for="JudetResedinta">Judet*</Label>
                      <FormFeedback>
                        {(validated || touched["JudetResedinta"]) &&
                          errors["JudetResedinta"]}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        )}
      </Formik>
      {children}
    </>
  );
});

export default UserMetadataForm;
