// Packages
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
// Pages
import NotFound from "../pages/NotFound";
// Utils
import { pageTitleBase, userHasAccess } from "../utils";

const PrivateRoute = React.memo(
  ({ component: Component, accessRoles = [], pageTitle = "", ...rest }) => {
    useEffect(() => {
      document.title = pageTitle
        ? `${pageTitleBase} - ${pageTitle}`
        : pageTitleBase;
    }, [pageTitle]);

    return (
      <Route
        {...rest}
        render={(props) =>
          userHasAccess(accessRoles) ? <Component {...props} /> : <NotFound />
        }
      />
    );
  }
);

export default PrivateRoute;
