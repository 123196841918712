import { getUserRole, getUserEmail } from ".";

export function isAuthenticated() {
  const email = getUserEmail();
  const role = getUserRole();

  if (!email || !role) {
    return false;
  }

  return true;
}

export function login({ email, role }) {
  localStorage.setItem("email", email);
  localStorage.setItem("role", role);
}

export function logout() {
  localStorage.clear();
}

export function userHasAccess(accessRoles) {
  const isAuth = isAuthenticated();

  if (!isAuth) return false;
  if (!accessRoles || accessRoles.length <= 0) return true;

  const userRole = getUserRole();

  return accessRoles.includes(userRole);
}