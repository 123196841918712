// Packages
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
// Utils
import { isAuthenticated, pageTitleBase, userHasAccess } from "../utils";

const FallbackRoute = React.memo(
  ({
    main: Main,
    second: Second,
    fallback: Fallback,
    accessRoles = [],
    mainTitle = "",
    secondTitle = "",
    fallbackTitle = "",
    ...rest
  }) => {
    const isMain = userHasAccess(accessRoles);
    const isSecond = isAuthenticated();

    useEffect(() => {
      if (isMain) {
        document.title = mainTitle
          ? `${pageTitleBase} - ${mainTitle}`
          : pageTitleBase;
      } else
      if (isSecond) {
        document.title = secondTitle
          ? `${pageTitleBase} - ${secondTitle}`
          : pageTitleBase;
      } else {
        document.title = fallbackTitle
          ? `${pageTitleBase} - ${fallbackTitle}`
          : pageTitleBase;
      }
    }, [isMain, isSecond]);

    return (
      <Route
        {...rest}
        render={(props) =>
          isMain ? (
            <Main {...props} />
          ) : isSecond ? (
            <Second {...props} />
          ) : (
            <Fallback {...props} />
          )
        }
      />
    );
  }
);

export default FallbackRoute;
