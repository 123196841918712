import { toast } from "react-toastify";
import { logout } from ".";

export default function catchHandler(history, err) {
  const [swapedHistory, swapedErr] = history && !err ? [err, history] : [history, err];
  const { response = {} } = swapedErr;
  const { data = {} } = response;
  const { msg, authenticate, ctk } = data;

  if (msg && !ctk) {
    toast.error(msg);
  }

  if (swapedHistory && authenticate === true) {
    logout();
    swapedHistory && swapedHistory.push("/login");
  }

  return data;
}
