// Packages
import React, { useState, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import { catchHandler, login } from "../utils";
// Styling
import "./ResetPassword.css";

const ResetPassword = React.memo((props) => {
  const rootPathname = useMemo(() => "/", []);
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    activationPin: "",
    password: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .post(`/api/auth/change-password/${token}`, fields)
        .then((res) => {
          const data = res.data;
          login(data);
          history.push(rootPathname);
        })
        .catch((err) => {
          const { activationPin = "", password = "", password2 = "" } = catchHandlerBinded(err);
          setLoading(false);
          setErrors({ activationPin, password, password2 });
        });
    },
    [history, fields, rootPathname]
  );

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        onSubmit(event);
      }
    },
    [onSubmit]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Resetare Parola</h2>
            <Form onKeyPress={onKeyPress}>
              <FormGroup>
                <Label for="activationPin">Pin</Label>
                <Input
                  id="activationPin"
                  name="activationPin"
                  type="text"
                  invalid={Boolean(errors["activationPin"])}
                  value={fields["activationPin"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["activationPin"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="on"
                  invalid={Boolean(errors["password"])}
                  value={fields["password"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["password"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password">Confirm Password</Label>
                <Input
                  id="password2"
                  name="password2"
                  type="password"
                  autoComplete="on"
                  invalid={Boolean(errors["password2"])}
                  value={fields["password2"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["password2"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Resetare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default ResetPassword;
