// Packages
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Input,
  InputGroup,
} from "reactstrap";
import axios from "axios";
import { BsSearch } from "react-icons/bs";
// Utils
import { catchHandler, getRequestHeaders } from "../utils";
// Components
import Pagination from "../components/Pagination";
// Styling
import "./UserWhatsappGroups.css";

const UserWhatsappGroups = React.memo((props) => {
  const { state: whatsappGroupState = {} } = useLocation();
  const { pageNumber = 1 } = whatsappGroupState;
  const [loading, setLoading] = useState(false);
  const [whatsappGroups, setUserWhatsappGroups] = useState([]);
  const [taxError, setTaxError] = useState(true);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState("");

  const getData = useCallback((currentPage, searchValue) => {
    setLoading(true);

    axios
      .get(
        `/api/whatsapp-groups/list-user-whatsapp-groups`,
        getRequestHeaders(
          Object.assign(
            {
              page: currentPage,
            },
            searchValue && {
              search: searchValue,
            }
          )
        )
      )
      .then((res) => {
        const { data = {} } = res;
        const { items = [], totalCount = 0, taxError = false } = data;
        setUserWhatsappGroups(items);
        setTotalCount(totalCount);
        setTaxError(taxError);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const getRows = useCallback(
    () =>
      whatsappGroups.length ? (
        whatsappGroups.map(({ _id, link, location, subject }) => (
          <tr key={`whatsapp-group-row-${_id}`}>
            <td>
              <Link to={{ pathname: link }} target="_blank">
                {link}
              </Link>
            </td>
            <td>{location?.name}</td>
            <td>{subject?.name}</td>
          </tr>
        ))
      ) : taxError ? (
        <tr>
          <td colSpan={3} className="text-center">
            <span>Pentru a avea acces la grupurile de Whatsapp, trebuie sa platesti mai intai taxa!</span>
          </td>
        </tr>
      ) : (
        <tr>
          <td colSpan={3} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ),
    [whatsappGroups, taxError]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback(
    (event) => {
      const { type, key } = event;

      if (type === "click" || (type === "keypress" && key === "Enter")) {
        getData(1, searchValue);
      }
    },
    [searchValue]
  );

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Grupuri de Whatsapp ({totalCount})</h3>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-end">
        <Col className="col-3 d-inline-flex">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyPress={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>Link</th>
                <th>Locatie</th>
                <th>Disciplina</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3} className="text-center">
                    <Spinner
                      color="dark"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>
    </Container>
  );
});

export default UserWhatsappGroups;
