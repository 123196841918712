// Packages
import React from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import { BsFillSendExclamationFill  } from "react-icons/bs";
// Styling
import "./RegistrationComplete.css";

const RegistrationComplete = React.memo((props) => {
  return (
    <Container className="mt-5 mb-5 text-center">
      <Row className="mt-5">
        <Col md={4}></Col>
        <Col md={4}>
          <Card
            style={{
              width: "32rem",
            }}
          >
            <CardBody>
              <CardTitle tag="h1">Atenție!</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                <strong>Procesul de înscriere nu este finalizat!</strong>
              </CardSubtitle>
            </CardBody>
            <BsFillSendExclamationFill className="registration-complete-icon" />
            <CardBody>
              <CardText>
                Va rugam sa va verificati adresa de email pentru activarea
                utilizatorului si efectuarea platii. <br />
                Verifica adresa de mail, inclusiv folderul spam/junk. Vei gasi
                un email ce contine un link si un pin. <br /> Apasa pe link,
                introdu adresa ta de mail si pinul si vei fi directionat catre
                pagina de plata a cotizației.
                <p>
                  <strong>
                    Daca nu ati finalizat crearea contului in 15 minute,
                    procesul trebuie reluat de la inceput!
                  </strong>
                </p>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default RegistrationComplete;
