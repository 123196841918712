// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
import { BsTrashFill } from "react-icons/bs";
// Utils
import {
  catchHandler,
  entriesToOptions,
  getRequestHeaders,
  getSelectStyles,
  collaborationTypesLabels,
  taxPaidOptions,
  yearsOptions,
  enumToOptions,
} from "../utils";
// Styling
import "./Subscriptions.css";

const Subscriptions = React.memo((props) => {
  const { id: userId } = useParams();
  const rootPathname = useMemo(() => "/users-management", []);
  const [userEmail, setUserEmail] = useState("");
  const [coursesOptions, setCoursesOptions] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState({});
  const [locationsOptions, setLocationsOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    collaboration: "",
    taxPaid: "",
    membershipYear: "",
    subscriptions: [],
    activityComment: "",
  });
  const [errors, setErrors] = useState({});
  const collaborationsOptions = useMemo(
    () => enumToOptions(collaborationTypesLabels),
    []
  );
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`/api/courses/select-courses`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        setCoursesOptions(data.map(entriesToOptions));
      })
      .catch(catchHandlerBinded);

    axios
      .get(`/api/subjects/select-mapped-subjects-options`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        setSubjectsOptions(data);
      })
      .catch(catchHandlerBinded);

    axios
      .get(
        `/api/locations/select-mapped-locations-options`,
        getRequestHeaders()
      )
      .then((res) => {
        const { data = {} } = res;
        setLocationsOptions(data);
      })
      .catch(catchHandlerBinded);

    axios
      .get(`/api/users/get-subscriptions/${userId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const {
          collaboration = "",
          taxPaid = "",
          membershipYear = "",
          subscriptions = [],
          activityComment,
          email
        } = data;
        setFields({
          collaboration,
          taxPaid,
          membershipYear,
          subscriptions,
          activityComment,
        });
        setUserEmail(email);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .patch(
          `/api/users/edit-subscriptions/${userId}`,
          fields,
          getRequestHeaders()
        )
        .then((res) => {
          const { data = {} } = res;
          const { email = "" } = data;
          toast.success(
            `Inrolarile utilizatorului [${email}] au fost modificate cu succes`
          );
          history.push(rootPathname);
        })
        .catch((err) => {
          const { name = "", description = "" } = catchHandler(history, err);
          setErrors({ name, description });
          setLoading(false);
        });
    },
    [userId, history, fields, rootPathname]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;

    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onSelectChange = useCallback((subscriptionId, option, action) => {
    const { value } = option || {};
    const { name } = action || {};

    if (subscriptionId === false) {
      setFields((prevFields) => ({ ...prevFields, [name]: value }));
    } else {
      setFields((prevFields) => {
        const { subscriptions = [] } = prevFields;
        const newSubscriptions = [...subscriptions];
        const [fieldName] = name.split("-");
        newSubscriptions[subscriptionId][fieldName] = value;
        return {
          ...prevFields,
          subscriptions: newSubscriptions,
        };
      });
    }
  }, []);

  const onAddSubscriptionClick = useCallback(() => {
    setFields((prevFields) => {
      const { subscriptions = [] } = prevFields;
      const newSubscriptions = [...subscriptions];
      newSubscriptions.push({
        course: "",
        subject: "",
        location: "",
      });
      return {
        ...prevFields,
        subscriptions: newSubscriptions,
      };
    });
  }, []);

  const onRemoveSubscriptionClick = useCallback((subscriptionId) => {
    setFields((prevFields) => {
      const { subscriptions = [] } = prevFields;
      const newSubscriptions = [...subscriptions];
      newSubscriptions.splice(subscriptionId, 1);
      return {
        ...prevFields,
        subscriptions: newSubscriptions,
      };
    });
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" lg={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mx-auto text-center">
              Administrare Inrolari
            </h2>
            {userEmail && (
              <h4 className="mb-5 mx-auto text-center">
                ( {userEmail} )
              </h4>
            )}
            <Form>
              {fields.subscriptions?.map((subscription, idx) => (
                <Row
                  key={`subscription-container-row-${idx}`}
                  className="subscription-container"
                >
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`course-${idx}`}>Cursul</Label>
                      <Select
                        id={`course-${idx}`}
                        name={`course-${idx}`}
                        className={
                          Boolean(Boolean(errors[`course-${idx}`]))
                            ? "is-invalid"
                            : ""
                        }
                        placeholder="Alegeti cursul"
                        noOptionsMessage={() => <span>Fara rezultat</span>}
                        styles={getSelectStyles(
                          Boolean(errors[`course-${idx}`])
                        )}
                        isLoading={loading}
                        type="text"
                        value={coursesOptions.filter(
                          ({ value }) => subscription.course === value
                        )}
                        options={coursesOptions}
                        onChange={onSelectChange.bind(null, idx)}
                      />
                      <FormFeedback>{errors[`course-${idx}`]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`subject-${idx}`}>Disciplina</Label>
                      <Select
                        id={`subject-${idx}`}
                        name={`subject-${idx}`}
                        className={
                          Boolean(Boolean(errors[`subject-${idx}`]))
                            ? "is-invalid"
                            : ""
                        }
                        placeholder="Alegeti disciplina"
                        noOptionsMessage={() => <span>Fara rezultat</span>}
                        styles={getSelectStyles(
                          Boolean(errors[`subject-${idx}`])
                        )}
                        isLoading={loading}
                        type="text"
                        value={(
                          subjectsOptions[subscription.course] || []
                        ).filter(({ value }) => subscription.subject === value)}
                        options={subjectsOptions[subscription.course] || []}
                        onChange={onSelectChange.bind(null, idx)}
                      />
                      <FormFeedback>{errors[`subject-${idx}`]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for={`location-${idx}`}>Locatie</Label>
                      <Select
                        id={`location-${idx}`}
                        name={`location-${idx}`}
                        className={
                          Boolean(Boolean(errors[`location-${idx}`]))
                            ? "is-invalid"
                            : ""
                        }
                        placeholder="Alegeti locatia"
                        noOptionsMessage={() => <span>Fara rezultat</span>}
                        styles={getSelectStyles(
                          Boolean(errors[`location-${idx}`])
                        )}
                        isLoading={loading}
                        type="text"
                        value={(
                          locationsOptions[subscription.subject] || []
                        ).filter(
                          ({ value }) => subscription.location === value
                        )}
                        options={locationsOptions[subscription.subject] || []}
                        onChange={onSelectChange.bind(null, idx)}
                      />
                      <FormFeedback>{errors[`location-${idx}`]}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <FormGroup className="subscription-remove-button">
                    <Button
                      color="danger"
                      onClick={onRemoveSubscriptionClick.bind(null, idx)}
                      disabled={loading}
                    >
                      <BsTrashFill />
                    </Button>
                  </FormGroup>
                </Row>
              ))}
              <FormGroup className="d-flex justify-content-start mt-1">
                <Button
                  color="success"
                  onClick={onAddSubscriptionClick}
                  disabled={loading}
                >
                  Adaugare Inrolare
                </Button>
              </FormGroup>
              <Row
                key="subscription-container-global-settings"
                className="subscription-container"
              >
                <Col md={4}>
                  <FormGroup>
                    <Label for="collaboration">Tip colaborare</Label>
                    <Select
                      id="collaboration"
                      name="collaboration"
                      className={
                        Boolean(Boolean(errors["collaboration"]))
                          ? "is-invalid"
                          : ""
                      }
                      placeholder="Colaborare"
                      noOptionsMessage={() => <span>Fara rezultat</span>}
                      styles={getSelectStyles(Boolean(errors["collaboration"]))}
                      isLoading={loading}
                      type="text"
                      value={
                        collaborationsOptions.find(
                          ({ value }) => value === fields["collaboration"]
                        ) || null
                      }
                      options={collaborationsOptions}
                      onChange={onSelectChange.bind(null, false)}
                    />
                    <FormFeedback>{errors["collaboration"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="taxPaid">Taxa Platita</Label>
                    <Select
                      id="taxPaid"
                      name="taxPaid"
                      className={
                        Boolean(Boolean(errors["taxPaid"])) ? "is-invalid" : ""
                      }
                      placeholder="Taxa"
                      noOptionsMessage={() => <span>Fara rezultat</span>}
                      styles={getSelectStyles(Boolean(errors["taxPaid"]))}
                      isLoading={loading}
                      type="text"
                      value={
                        taxPaidOptions.find(
                          ({ value }) => value === fields["taxPaid"]
                        ) || null
                      }
                      options={taxPaidOptions}
                      onChange={onSelectChange.bind(null, false)}
                    />
                    <FormFeedback>{errors["taxPaid"]}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="membershipYear">An inscriere</Label>
                    <Select
                      id="membershipYear"
                      name="membershipYear"
                      className={
                        Boolean(Boolean(errors["membershipYear"]))
                          ? "is-invalid"
                          : ""
                      }
                      placeholder="An"
                      noOptionsMessage={() => <span>Fara rezultat</span>}
                      styles={getSelectStyles(
                        Boolean(errors["membershipYear"])
                      )}
                      isLoading={loading}
                      type="text"
                      value={
                        yearsOptions.find(
                          ({ value }) => value === fields["membershipYear"]
                        ) || null
                      }
                      options={yearsOptions}
                      onChange={onSelectChange.bind(null, false)}
                    />
                    <FormFeedback>{errors["membershipYear"]}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="activityComment">Comentarii</Label>
                <Input
                  id="activityComment"
                  name="activityComment"
                  type="textarea"
                  invalid={Boolean(errors["activityComment"])}
                  value={fields["activityComment"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["activityComment"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default Subscriptions;
