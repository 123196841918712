export function calculateAge(birthday) {
  const today = new Date();
  const birthDate = new Date(birthday);

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();

  if (today.getDate() < birthDate.getDate()) {
    ageMonths--;
  }

  if (ageMonths < 0) {
    ageYears--;
    ageMonths = 12 + ageMonths;
  }

  return [ageYears, ageMonths];
}

export function oneYearIncrease(originalDateString) {
  if (!originalDateString) return "";

  const originalDate = new Date(originalDateString);

  // Increment the year by 1
  const newYear = originalDate.getFullYear() + 1;

  // Create a new Date object with the incremented year
  const newDate = new Date(originalDate);
  newDate.setFullYear(newYear);

  // Format the new date as a string
  return newDate.toISOString();
}

export function isAfterLimit(dateToCheck, ...limit) { // limit: [month - 1, day, ...]
  const limitDate = new Date(dateToCheck.getFullYear(), ...limit);
  return dateToCheck > limitDate;
}

export const AGE_LIMITS = {
  UNDER: 15 * 12, // 15years
  ALMOST_UNDER: 16 * 12, // 16years
  MINOR: 18 * 12, // 18years
  ALMOST_OVER: 22 * 12 + 3, // 22years, 3months
  OVER: 23 * 12, // 23years
};

export const MONTH_NAMES = [
  "Ianuarie",
  "Februarie",
  "Martie",
  "Aprilie",
  "Mai",
  "Iunie",
  "Iulie",
  "August",
  "Septembrie",
  "Octombrie",
  "Noiembrie",
  "Decembrie",
];

export const MIN_YEAR = 1900;
export const YEARS = ((minYear) => {
  const currentYear = new Date().getFullYear();
  const resultArr = [];

  for (let i = minYear; i <= currentYear; i++) {
    resultArr.push(i);
  }

  return resultArr;
})(MIN_YEAR);

export const yearsOptions = YEARS.reverse().map((year) => ({
  label: year,
  value: year,
}));

export function formatDateToDDMMYYYY(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid date input");
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

// not used for the moment
const PickMonthElement = ({
  displayDate,
  _minDate,
  _maxDate,
  onChangeMonth,
  onChangeYear,
}) => {
  const [month, setMonth] = useState(new Date(displayDate).getMonth());
  const [year, setYear] = useState(new Date(displayDate).getFullYear());

  useEffect(() => {
    setMonth(new Date(displayDate).getMonth());
    setYear(new Date(displayDate).getFullYear());
  }, [displayDate]);

  const handleChangeMonth = (ev) => {
    const m = ev.target.value;
    setMonth(m);
    onChangeMonth(m);
  };

  const handleChangeYear = (ev) => {
    const y = ev.target.value;
    setYear(y);
    onChangeYear(y);
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <div style={{ flex: "50%" }}>
        <Input type="select" value={month} onChange={handleChangeMonth}>
          {MONTH_NAMES.map((lmonth, lidx) => {
            return (
              <option key={`month_${lidx}`} value={lidx}>
                {lmonth}
              </option>
            );
          })}
        </Input>
      </div>
      <div style={{ flex: "50%" }}>
        <Input type="select" value={year} onChange={handleChangeYear}>
          {YEARS.map((lyear) => {
            return (
              <option key={`year${lyear}`} value={lyear}>
                {lyear}
              </option>
            );
          })}
        </Input>
      </div>
    </div>
  );
};