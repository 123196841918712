import * as Yup from "yup";

export const validationSchemaUserMetadata = Yup.object().shape({
  Nume: Yup.string()
    .max(50, "Prea multe caractere")
    .required("Camp obligatoriu"),
  Prenume: Yup.string()
    .max(50, "Prea multe caractere")
    .required("Camp obligatoriu"),
  CNP: Yup.string()
    .matches(/^[0-9]{13}$/, "CNP Invalid")
    .required("Camp obligatoriu"),
  Serie: Yup.string()
    .matches(/^[A-Za-z]{2}$/, "Serie CI Invalida")
    .required("Camp obligatoriu"),
  Numar: Yup.string()
    .min(6, "Numar CI prea scurt")
    .max(10, "Numar CI prea lung")
    .matches(/^[0-9]{6,10}$/, "Numar CI Invalid")
    .required("Camp obligatoriu"),
  Greutate: Yup.number()
    .min(0, "Valoarea prea mica")
    .max(300, "Valoarea prea mare")
    .required("Camp obligatoriu"),
  Inaltime: Yup.number()
    .min(0, "Valoarea prea mica")
    .max(300, "Valoarea prea mare")
    .required("Camp obligatoriu"),
  Email: Yup.string().email("Email Invalid").required("Camp obligatoriu"),
  Telefon: Yup.string()
    .max(15, "Prea multe caractere")
    .required("Camp obligatoriu"),
  JudetDomiciliu: Yup.string()
    .max(50, "Prea multe caractere")
    .required("Camp obligatoriu"),
  LocalitateDomiciliu: Yup.string()
    .max(50, "Prea multe caractere")
    .required("Camp obligatoriu"),
  StradaDomiciliu: Yup.string()
    .max(100, "Prea multe caractere")
    .required("Camp obligatoriu"),
  NumarDomiciliu: Yup.string()
    .max(10, "Prea multe caractere")
    .required("Camp obligatoriu"),
  BlocDomiciliu: Yup.string().max(10, "Prea multe caractere"),
  ScaraDomiciliu: Yup.string().max(10, "Prea multe caractere"),
  ApartamentDomiciliu: Yup.string().max(10, "Prea multe caractere"),
  AceeasiAdresa: Yup.boolean(),
  JudetResedinta: Yup.string().when("AceeasiAdresa", {
    is: false,
    then: () =>
      Yup.string().max(50, "Prea multe caractere").required("Camp obligatoriu"),
  }),
  LocalitateResedinta: Yup.string().when("AceeasiAdresa", {
    is: false,
    then: () =>
      Yup.string().max(50, "Prea multe caractere").required("Camp obligatoriu"),
  }),
  StradaResedinta: Yup.string().when("AceeasiAdresa", {
    is: false,
    then: () =>
      Yup.string()
        .max(100, "Prea multe caractere")
        .required("Camp obligatoriu"),
  }),
  NumarResedinta: Yup.string().when("AceeasiAdresa", {
    is: false,
    then: () =>
      Yup.string().max(10, "Prea multe caractere").required("Camp obligatoriu"),
  }),
  BlocResedinta: Yup.string().when("AceeasiAdresa", {
    is: false,
    then: () => Yup.string().max(10, "Prea multe caractere"),
  }),
  ScaraResedinta: Yup.string().when("AceeasiAdresa", {
    is: false,
    then: () => Yup.string().max(10, "Prea multe caractere"),
  }),
  ApartamentResedinta: Yup.string().when("AceeasiAdresa", {
    is: false,
    then: () => Yup.string().max(10, "Prea multe caractere"),
  }),
});

export const defaultValuesUserMetadata = {
  Nume: "",
  Prenume: "",
  CNP: "",
  Serie: "",
  Numar: "",
  JudetDomiciliu: "",
  LocalitateDomiciliu: "",
  StradaDomiciliu: "",
  NumarDomiciliu: "",
  BlocDomiciliu: "",
  ScaraDomiciliu: "",
  ApartamentDomiciliu: "",
  AceeasiAdresa: true,
  JudetResedinta: "",
  LocalitateResedinta: "",
  StradaResedinta: "",
  NumarResedinta: "",
  BlocResedinta: "",
  ScaraResedinta: "",
  ApartamentResedinta: "",
  Email: "",
  Telefon: "",
};
