// WARNING: keep this value in sync with the server value
export const userRoles = {
  ADMIN: "admin",
  EMPLOYEE: "employee",
  LEGITIMAT: "legitimat",
};

export const userRoleToLabel = {
  [userRoles.ADMIN]: "Administrator",
  [userRoles.EMPLOYEE]: "Angajat",
  [userRoles.LEGITIMAT]: "Legitimat",
};

export const userRoleLegitimatOption = {
  value: userRoles.LEGITIMAT,
  label: userRoleToLabel[userRoles.LEGITIMAT],
};

export const userRoleOptions = {
  [userRoles.ADMIN]: {
    value: userRoles.ADMIN,
    label: userRoleToLabel[userRoles.ADMIN],
  },
  [userRoles.EMPLOYEE]: {
    value: userRoles.EMPLOYEE,
    label: userRoleToLabel[userRoles.EMPLOYEE],
  },
  // TODO: hide for the moment, maybe show it again when we can edit all details
  // [userRoles.LEGITIMAT]: { value: userRoles.LEGITIMAT, label: userRoleToLabel[userRoles.LEGITIMAT] },
};

export function getUserEmail() {
  const email = localStorage.getItem("email");

  if (!email) {
    return "";
  }

  return email;
}

export function getUserRole() {
  const role = localStorage.getItem("role");

  if (!role) {
    return "";
  }

  return role;
}

export function processUserName(
  { metadata = {}, email = "" },
  noEmailFlag = false
) {
  const { Nume = "", Prenume = "" } = metadata;
  const name = `${Nume} ${Prenume}`.trim();

  return noEmailFlag ? name : !name ? email : `${name} (${email})`;
}
