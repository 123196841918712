// Packages
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Input,
  InputGroup,
  Badge,
} from "reactstrap";
import axios from "axios";
import { BsSearch, BsPaypal } from "react-icons/bs";
// Utils
import { getRequestHeaders, catchHandler } from "../utils";
// Components
import Pagination from "../components/Pagination";
// Styling
import "./Receipts.css";

// TODO: export this
const paidTypes = {
  PAID: "paid",
  UNPAID: "unpaid",
  TOPAY: "topay",
};

const Receipts = React.memo((props) => {
  const { state: locationState = {} } = useLocation();
  const { pageNumber = 1 } = locationState;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const [receipts, setReceipts] = useState([]);
  const [paid, setPaid] = useState(undefined);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState("");

  const getData = useCallback((currentPage, searchValue) => {
    setLoading(true);

    axios
      .get(
        `/api/receipts/list-receipts`,
        getRequestHeaders(
          Object.assign(
            {
              page: currentPage,
            },
            searchValue && {
              search: searchValue,
            }
          )
        )
      )
      .then((res) => {
        const { data = {} } = res;
        const { paid, items = [], totalCount = 0 } = data;
        setPaid(paid);
        setReceipts(items);
        setTotalCount(totalCount);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const getRows = useCallback(
    () =>
      receipts.length ? (
        receipts.map(({ orderDesc, amount, action }, id) => (
          <tr key={`receipt-row-${id}`}>
            <td>{orderDesc}</td>
            <td>{amount}</td>
            <td>
              {Boolean(Number(action)) ? "Plata esuata" : "Plata cu success"}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={10} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ),
    [receipts]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback(
    (event) => {
      const { type, key } = event;

      if (type === "click" || (type === "keyup" && key === "Enter")) {
        getData(1, searchValue);
      }
    },
    [searchValue]
  );

  const onPayment = useCallback(() => {
    axios
      .get(`/api/payment/get-redirect`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        const { redirectUrl = {} } = data;
        window.location.href = redirectUrl;
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Plati ({totalCount})</h3>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-between">
        <Col className="col-4 justify-content-start">
          {paid === paidTypes.PAID ? (
            <>
              <Badge className="p-2" color="success" pill>
                <h6>Taxa platita</h6>
              </Badge>
              <h6>
                Pentru a obtine mai multe informatii te invitam sa intri pe grupul
                de Whatsapp, accesand meniul de mai sus
              </h6>
            </>
          ) : paid === paidTypes.TOPAY ? (
            <Badge className="p-2" color="warning" pill>
              <h6>Taxa trebuie platita in curand</h6>
            </Badge>
          ) : paid === paidTypes.UNPAID ? (
            <Badge className="p-2" color="danger" pill>
              <h6>Taxa neplatita</h6>
            </Badge>
          ) : null}
        </Col>
        <Col className="col-4 d-inline-flex justify-content-end">
          {paid === paidTypes.TOPAY || paid === paidTypes.UNPAID ? (
            <Button
              disabled={loading}
              color="success"
              size="sm"
              onClick={onPayment}
            >
              <BsPaypal /> Plateste taxa acum
            </Button>
          ) : null}
        </Col>
        <Col className="col-4 justify-content-end">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyUp={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>Nume</th>
                <th>Valoare</th>
                <th>Plata</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    <Spinner
                      color="dark"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>
    </Container>
  );
});

export default Receipts;
