// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import { catchHandler, getRequestHeaders, getSelectStyles } from "../utils";
// Styling
import "./AddSubject.css";

const AddSubject = React.memo((props) => {
  const rootPathname = useMemo(() => "/subjects", []);
  const [loading, setLoading] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [fields, setFields] = useState({
    name: "",
    courses: [],
    description: "",
    deactivatedForSubventionat: false,
  });
  const [errors, setErrors] = useState({});
  const [coursesOptions, setCoursesOptions] = useState([]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  useEffect(() => {
    setLoadingCourses(true);

    axios
      .get(`/api/courses/select-courses`, getRequestHeaders())
      .then((res) => {
        const { data = [] } = res;
        const coursesOptions = data.map(({ _id, name }) => ({
          value: _id,
          label: name,
        }));
        setCoursesOptions(coursesOptions);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingCourses(false);
      });
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .post("/api/subjects/create-subject", fields, getRequestHeaders())
        .then((res) => {
          const { data = {} } = res;
          const { name = "" } = data;
          toast.success(`Disciplina [${name}] a fost adaugata cu succes`);
          history.push(rootPathname);
        })
        .catch((err) => {
          const {
            name = "",
            courses = "",
            description = "",
          } = catchHandler(history, err);
          setErrors({ name, courses, description });
          setLoading(false);
        });
    },
    [history, fields, rootPathname]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onCheckChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, checked } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: checked }));
  }, []);

  const onMultiSelectChange = useCallback((options, action) => {
    const { name = "" } = action || {};
    setFields((prevFields) => ({
      ...prevFields,
      [name]: options.map(({ value }) => value),
    }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">Adaugare Disciplina</h2>
            <Form>
              <FormGroup>
                <Label for="name">Denumire</Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  invalid={Boolean(errors["name"])}
                  value={fields["name"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["name"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="courses">Cursuri</Label>
                <Select
                  name="courses"
                  className={Boolean(errors["courses"]) ? "is-invalid" : ""}
                  placeholder="Alegeti cursurile"
                  noOptionsMessage={() => <span>Fara rezultat</span>}
                  isClearable={true}
                  isMulti={true}
                  isLoading={loadingCourses}
                  onChange={onMultiSelectChange}
                  styles={getSelectStyles(Boolean(errors["courses"]))}
                  value={coursesOptions.filter(({ value }) =>
                    fields["courses"].includes(value)
                  )}
                  options={coursesOptions}
                />
                <FormFeedback>{errors["courses"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="description">Descriere</Label>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  invalid={Boolean(errors["description"])}
                  value={fields["description"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["description"]}</FormFeedback>
              </FormGroup>
              <FormGroup check>
                <Input
                  id="deactivatedForSubventionat"
                  name="deactivatedForSubventionat"
                  type="checkbox"
                  checked={fields["deactivatedForSubventionat"]}
                  onChange={onCheckChange}
                />
                <Label for="deactivatedForSubventionat" check>
                  Dezactivat pentru contractele subventionate
                </Label>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading || loadingCourses}
                >
                  Salvare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default AddSubject;
