// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Button,
  Input,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import {
  BsDownload,
  BsSearch,
  BsFiletypeXlsx,
  BsFillPersonVcardFill,
} from "react-icons/bs";
// Utils
import {
  getRequestHeaders,
  catchHandler,
  collaborationTypesLabels,
  yearsOptions,
  taxPaidOptions,
  entriesToOptions,
  userHasAccess,
  userRoles,
  enumToOptions,
} from "../utils";
// Components
import Pagination from "../components/Pagination";
// Styling
import "./Dashboard.css";

const Dashboard = React.memo((props) => {
  const { state: locationState = {} } = useLocation();
  const { pageNumber = 1 } = locationState;
  const [loading, setLoading] = useState(false);
  const [loadingActions, setLoadingActions] = useState(false);
  const [registredUsers, setRegistredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);
  const [searchValue, setSearchValue] = useState("");
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState([]);
  const [coursesOptions, setCoursesOptions] = useState([]);
  const collaborationsOptions = useMemo(
    () => enumToOptions(collaborationTypesLabels),
    []
  );
  const [filters, setFilters] = useState({
    membershipYear: "",
    taxPaid: "",
    location: "",
    subject: "",
    course: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [activityComment, setActivityComment] = useState("");
  const isAdmin = userHasAccess([userRoles.ADMIN]);

  const getData = useCallback((currentPage, filters, searchValue) => {
    setLoading(true);

    axios
      .get(
        `/api/dashboard/list-registred-users`,
        getRequestHeaders(
          Object.assign(
            {
              page: currentPage,
              filters,
            },
            searchValue && {
              search: searchValue,
            }
          )
        )
      )
      .then((res) => {
        const { data = {} } = res;
        const { items = [], totalCount = 0 } = data;
        setRegistredUsers(items);
        setTotalCount(totalCount);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getModalData = useCallback((userId) => {
    setModalLoading(true);

    axios
      .get(`/api/dashboard/get-user-data/${userId}`, getRequestHeaders())
      .then((res) => {
        const { data = {} } = res;
        setActivityComment(data.activityComment);
        setModalData(data);
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setModalLoading(false);
      });
  }, []);

  const getOptions = useCallback(() => {
    setLoadingActions(true);
    Promise.all([
      axios.get(`/api/locations/select-locations`, getRequestHeaders({})),
      axios.get(`/api/subjects/select-subjects`, getRequestHeaders({})),
      axios.get(`/api/courses/select-courses`, getRequestHeaders({})),
    ])
      .then(([resLocations, resSubjects, resCourses]) => {
        const [
          { data: dataLocations = [] },
          { data: dataSubjects = [] },
          { data: dataCourses = [] },
        ] = [resLocations, resSubjects, resCourses];
        setLocationsOptions(dataLocations.map(entriesToOptions));
        setSubjectsOptions(dataSubjects.map(entriesToOptions));
        setCoursesOptions(dataCourses.map(entriesToOptions));
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingActions(false);
      });
  }, []);

  useEffect(() => {
    getData(currentPage, filters);
    getOptions();
  }, [currentPage, filters]);

  const onDownloadClick = useCallback((id, notarialAgreementFlag) => {
    setLoadingActions(true);

    const documentSubPath = !notarialAgreementFlag
      ? "download-identity-card"
      : "download-notarial-agreement";

    const documentTitle = !notarialAgreementFlag
      ? "Carte de identitate"
      : "Acord notarial";

    axios
      .get(
        `/api/dashboard/${documentSubPath}/${id}`,
        getRequestHeaders({ requestDownloadFilesFlag: true })
      )
      .then((res) => {
        const type = res.headers["content-type"];
        const blob = new Blob([res.data], { type, encoding: "UTF-8" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = documentTitle;
        link.click();
        link.remove();
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingActions(false);
      });
  }, []);

  const toggleModal = useCallback(
    (userId) => {
      if (typeof userId === "string") {
        getModalData(userId);
      } else {
        setActivityComment("");
        setModalData(null);
      }

      setModalOpen((prevState) => !prevState);
    },
    [getModalData]
  );

  const submitEditActivityComment = useCallback(
    (userId, activityComment) => {
      setLoading(true);

      axios
        .patch(
          `/api/dashboard/edit-activity-comment/${userId}`,
          { activityComment },
          getRequestHeaders()
        )
        .then((res) => {
          const { data = {} } = res;
          const { email = "" } = data;
          toast.success(
            `Comentariile utilizatorului [${email}] au fost modificate cu succes`
          );
          setActivityComment("");
          setModalData(null);
          setModalOpen(false);
        })
        .catch(catchHandlerBinded)
        .finally(() => {
          setLoading(false);
        });
    },
    [history]
  );

  const onActivityCommentChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setActivityComment(value);
  }, []);

  const getRows = useCallback(
    () =>
      registredUsers.length ? (
        registredUsers.map(
          (
            {
              _id,
              membershipId,
              email,
              metadata,
              nationalId,
              phoneNumber,
              identityCard,
              notarialAgreement,
              collaboration,
              membershipYear,
              taxPaid,
              subscriptions,
            },
            id
          ) => (
            <tr key={`registred-users-row-${_id}`}>
              <td>
                {membershipId ? (
                  String(membershipId).padStart(7, "0")
                ) : (
                  <Badge color="secondary" pill>
                    User inactiv
                  </Badge>
                )}
              </td>
              <td>{metadata.Nume}</td>
              <td>{metadata.Prenume}</td>
              <td>{email}</td>
              <td>{nationalId}</td>
              <td>{phoneNumber}</td>
              <td>{collaborationTypesLabels[collaboration]}</td>
              <td>{membershipYear}</td>
              <td>
                {taxPaid ? (
                  <Badge color="success" pill>
                    Taxa platita
                  </Badge>
                ) : (
                  <Badge color="danger" pill>
                    Taxa neplatita
                  </Badge>
                )}
              </td>
              <td>
                {subscriptions.map(({ location }) => location?.name).join(", ")}
              </td>
              <td>
                {subscriptions.map(({ subject }) => subject?.name).join(", ")}
              </td>
              <td>
                {subscriptions.map(({ course }) => course?.name).join(", ")}
              </td>
              <td className="text-center">
                <Button
                  disabled={loadingActions}
                  color="primary"
                  size="sm"
                  onClick={toggleModal.bind(null, _id)}
                >
                  <BsFillPersonVcardFill />
                </Button>
              </td>
              <td className="text-center">
                {Boolean(identityCard) ? (
                  <Button
                    disabled={loadingActions}
                    color="info"
                    size="sm"
                    onClick={onDownloadClick.bind(null, _id, false)}
                  >
                    <BsDownload />
                  </Button>
                ) : null}
              </td>
              <td className="text-center">
                {Boolean(notarialAgreement) ? (
                  <Button
                    disabled={loadingActions}
                    color="warning"
                    size="sm"
                    onClick={onDownloadClick.bind(null, _id, true)}
                  >
                    <BsDownload />
                  </Button>
                ) : null}
              </td>
            </tr>
          )
        )
      ) : (
        <tr>
          <td colSpan={15} className="text-center">
            <span>Fara rezultat</span>
          </td>
        </tr>
      ),
    [registredUsers, loadingActions, toggleModal]
  );

  const onSearchChange = useCallback((event) => {
    const { target = {} } = event;
    const { value } = target;
    setSearchValue(() => value);
  }, []);

  const handleSearch = useCallback(
    (event) => {
      const { type, key } = event;

      if (type === "click" || (type === "keyup" && key === "Enter")) {
        getData(1, filters, searchValue);
      }
    },
    [filters, searchValue]
  );

  const handleFilters = useCallback(
    (option, action) => {
      const { value } = option || {};
      const { name } = action || {};

      setFilters((prevFilters) => {
        const newFilters = { ...prevFilters, [name]: value };
        getData(1, newFilters, searchValue);
        return newFilters;
      });
    },
    [searchValue]
  );

  const handleExportExcel = useCallback(() => {
    setLoadingActions(true);

    axios
      .get(
        `/api/dashboard/export-to-excel`,
        getRequestHeaders(
          Object.assign(
            { requestBlobFlag: true, filters },
            searchValue && {
              search: searchValue,
            }
          )
        )
      )
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Export excel`;
        link.click();
        link.remove();
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoadingActions(false);
      });
  }, [filters, searchValue]);

  return (
    <Container className="mt-5 mb-5" style={{ maxWidth: "1400px" }}>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        size="lg"
        centered={true}
        scrollable={true}
      >
        <ModalHeader toggle={toggleModal}>Detalii utilizator</ModalHeader>
        <ModalBody>
          {modalLoading ? (
            <div className="text-center">
              <Spinner color="dark" style={{ width: "5rem", height: "5rem" }} />
            </div>
          ) : modalData ? (
            <>
              <h6>Detalii personale</h6>
              <Row key="dashboard-modal-row-personal-details-1">
                <Col md={6}>
                  <label>Nume:</label>&nbsp;
                  <span>{modalData.Nume}</span>
                </Col>
                <Col md={6}>
                  <label>Prenume:</label>&nbsp;
                  <span>{modalData.Prenume}</span>
                </Col>
              </Row>
              <Row key="dashboard-modal-row-personal-details-2">
                <Col md={6}>
                  <label>Email:</label>&nbsp;
                  <span>{modalData.Email}</span>
                </Col>
                <Col md={6}>
                  <label>Telefon:</label>&nbsp;
                  <span>{modalData.Telefon}</span>
                </Col>
              </Row>
              <Row key="dashboard-modal-row-personal-details-3">
                <Col md={6}>
                  <label>CNP:</label>&nbsp;
                  <span>{modalData.CNP}</span>
                </Col>
                <Col md={2}>
                  <label>Serie CI:</label>&nbsp;
                  <span>{modalData.Serie}</span>
                </Col>
                <Col md={4}>
                  <label>Numar CI:</label>&nbsp;
                  <span>{modalData.Numar}</span>
                </Col>
              </Row>
              <Row key="dashboard-modal-row-personal-details-4">
                <Col md={3}>
                  <label>Inaltime:</label>&nbsp;
                  <span>{modalData.Inaltime}cm</span>
                </Col>
                <Col md={3}>
                  <label>Greutate:</label>&nbsp;
                  <span>{modalData.Greutate}kg</span>
                </Col>
              </Row>
              <h6 className="mt-2">Adresa Domiciliu</h6>
              <Row key="dashboard-modal-row-personal-address-1">
                <Col md={4}>
                  <label>Str:</label>&nbsp;
                  <span>{modalData.StradaDomiciliu}</span>
                </Col>
                <Col md={2}>
                  <label>Nr:</label>&nbsp;
                  <span>{modalData.NumarDomiciliu}</span>
                </Col>
                <Col md={2}>
                  <label>Bl:</label>&nbsp;
                  <span>{modalData.BlocDomiciliu}</span>
                </Col>
                <Col md={2}>
                  <label>Sc:</label>&nbsp;
                  <span>{modalData.ScaraDomiciliu}</span>
                </Col>
                <Col md={2}>
                  <label>Ap:</label>&nbsp;
                  <span>{modalData.ApartamentDomiciliu}</span>
                </Col>
              </Row>
              <Row key="dashboard-modal-row-personal-address-2">
                <Col md={6}>
                  <label>Judet:</label>&nbsp;
                  <span>{modalData.JudetDomiciliu}</span>
                </Col>
                <Col md={6}>
                  <label>Localitate/Sector:</label>&nbsp;
                  <span>{modalData.LocalitateDomiciliu}</span>
                </Col>
              </Row>
              <h6 className="mt-2">Adresa Resedinta</h6>
              {modalData.AceeasiAdresa ? (
                <span>Aceeasi adresa ca cea de domiciliu</span>
              ) : (
                <>
                  <Row key="dashboard-modal-row-delivery-address-1">
                    <Col md={4}>
                      <label>Str:</label>&nbsp;
                      <span>{modalData.StradaResedinta}</span>
                    </Col>
                    <Col md={2}>
                      <label>Nr:</label>&nbsp;
                      <span>{modalData.NumarResedinta}</span>
                    </Col>
                    <Col md={2}>
                      <label>Bl:</label>&nbsp;
                      <span>{modalData.BlocResedinta}</span>
                    </Col>
                    <Col md={2}>
                      <label>Sc:</label>&nbsp;
                      <span>{modalData.ScaraResedinta}</span>
                    </Col>
                    <Col md={2}>
                      <label>Ap:</label>&nbsp;
                      <span>{modalData.ApartamentResedinta}</span>
                    </Col>
                  </Row>
                  <Row key="dashboard-modal-row-delivery-address-2">
                    <Col md={6}>
                      <label>Judet:</label>&nbsp;
                      <span>{modalData.JudetResedinta}</span>
                    </Col>
                    <Col md={6}>
                      <label>Localitate/Sector:</label>&nbsp;
                      <span>{modalData.LocalitateResedinta}</span>
                    </Col>
                  </Row>
                </>
              )}
              <h6 className="mt-2">Colaborare</h6>
              <Row key="dashboard-modal-row-collaboration">
                <Col md={4}>
                  <label>Tip Colaborare:</label>&nbsp;
                  <span>{modalData.collaboration}</span>
                </Col>
                <Col md={4}>
                  <label>An Inscriere:</label>&nbsp;
                  <span>{modalData.membershipYear}</span>
                </Col>
                <Col
                  md={4}
                  className={modalData.taxPaid ? "text-success" : "text-danger"}
                >
                  <span>
                    {modalData.taxPaid ? "Taxa Platita" : "Taxa Neplatita"}
                  </span>
                </Col>
              </Row>
              <h6 className="mt-2">Inscrieri</h6>
              {modalData.subscriptions?.map(
                ({ location, subject, course }, idx) => (
                  <Row key={`dashboard-modal-row-subscription-${idx}`}>
                    <Col md={4}>
                      <label>Locatie:</label>&nbsp;
                      <span>{location?.name}</span>
                    </Col>
                    <Col md={4}>
                      <label>Disciplina:</label>&nbsp;
                      <span>{subject?.name}</span>
                    </Col>
                    <Col md={4}>
                      <label>Curs:</label>&nbsp;
                      <span>{course?.name}</span>
                    </Col>
                  </Row>
                )
              )}
              <h6 className="mt-2">Comentarii</h6>
              <Row key="dashboard-modal-row-activity-comment">
                <Col md={12}>
                  {isAdmin ? (
                    <Input
                      id="activityComment"
                      name="activityComment"
                      type="textarea"
                      value={activityComment}
                      onChange={onActivityCommentChange}
                    />
                  ) : (
                    <pre>{modalData.activityComment}</pre>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <div className="text-center">
              <span>Fara rezultat</span>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between w-100">
          {isAdmin ? (
            <Button
              color="primary"
              onClick={submitEditActivityComment.bind(
                null,
                modalData?._id,
                activityComment
              )}
            >
              Salvare
            </Button>
          ) : (
            <span></span>
          )}
          <Button color="secondary" onClick={toggleModal}>
            Inchidere
          </Button>
        </ModalFooter>
      </Modal>
      <Row className="mt-5">
        <Col className="d-flex justify-content-between align-items-center">
          <span className="">
            <h3>Dashboard ({totalCount})</h3>
          </span>
        </Col>
      </Row>
      <Row className="mt-1 justify-content-between">
        <Col className="col-9 d-inline-flex justify-content-start">
          <InputGroup className="input-filters">
            <Select
              className="w-100"
              name="membershipYear"
              placeholder="An inscriere"
              noOptionsMessage={() => <span>Fara rezultat</span>}
              isClearable={true}
              onChange={handleFilters}
              value={
                yearsOptions.find(
                  ({ value }) => value === filters["membershipYear"]
                ) || null
              }
              options={yearsOptions}
            />
          </InputGroup>
          <InputGroup className="input-filters">
            <Select
              className="w-100"
              name="taxPaid"
              placeholder="Taxa"
              noOptionsMessage={() => <span>Fara rezultat</span>}
              isClearable={true}
              onChange={handleFilters}
              value={
                taxPaidOptions.find(
                  ({ value }) => value === filters["taxPaid"]
                ) || null
              }
              options={taxPaidOptions}
            />
          </InputGroup>
          <InputGroup className="input-filters">
            <Select
              className="w-100"
              name="location"
              placeholder="Locatie"
              noOptionsMessage={() => <span>Fara rezultat</span>}
              isClearable={true}
              onChange={handleFilters}
              value={
                locationsOptions.find(
                  ({ value }) => value === filters["location"]
                ) || null
              }
              options={locationsOptions}
            />
          </InputGroup>
          <InputGroup className="input-filters">
            <Select
              className="w-100"
              name="subject"
              placeholder="Disciplina"
              noOptionsMessage={() => <span>Fara rezultat</span>}
              isClearable={true}
              onChange={handleFilters}
              value={
                subjectsOptions.find(
                  ({ value }) => value === filters["subject"]
                ) || null
              }
              options={subjectsOptions}
            />
          </InputGroup>
          <InputGroup className="input-filters">
            <Select
              className="w-100"
              name="course"
              placeholder="Curs"
              noOptionsMessage={() => <span>Fara rezultat</span>}
              isClearable={true}
              onChange={handleFilters}
              value={
                coursesOptions.find(
                  ({ value }) => value === filters["course"]
                ) || null
              }
              options={coursesOptions}
            />
          </InputGroup>
          <InputGroup className="input-filters">
            <Select
              className="w-100"
              name="collaboration"
              placeholder="Colaborare"
              noOptionsMessage={() => <span>Fara rezultat</span>}
              isClearable={true}
              onChange={handleFilters}
              value={
                collaborationsOptions.find(
                  ({ value }) => value === filters["collaboration"]
                ) || null
              }
              options={collaborationsOptions}
            />
          </InputGroup>
        </Col>
        <Col className="col-3 d-inline-flex">
          <InputGroup>
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChange}
              onKeyUp={handleSearch}
              value={searchValue}
            />
            <Button
              disabled={loadingActions || loading}
              color="secondary"
              size="sm"
              onClick={handleSearch}
            >
              <BsSearch />
            </Button>
          </InputGroup>
          <Button
            className="export-excel"
            disabled={loadingActions || loading}
            color="success"
            size="sm"
            onClick={handleExportExcel}
          >
            <BsFiletypeXlsx />
          </Button>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nume</th>
                <th>Prenume</th>
                <th>Email</th>
                <th>CNP</th>
                <th>Numar telefon</th>
                <th>Tip colaborare</th>
                <th>An inscriere</th>
                <th>Plata taxa</th>
                <th>Locatie</th>
                <th>Disciplina</th>
                <th>Curs</th>
                <th>Detalii profil</th>
                <th className="text-center">Carte de identitate</th>
                <th className="text-center">Acord notarial</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={15} className="text-center">
                    <Spinner
                      color="dark"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </td>
                </tr>
              ) : (
                getRows()
              )}
            </tbody>
          </Table>
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            onSetPage={setCurrentPage}
          />
        </Col>
      </Row>
    </Container>
  );
});

export default Dashboard;
