// Packages
import React, { useCallback } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { BsPatchCheckFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
// Styling
import "./PaymentSuccess.css";

const PaymentSuccess = React.memo((props) => {
  const history = useHistory();

  const onHomeClick = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <Container className="mt-5 mb-5 text-center">
      <Row className="mt-5">
        <Col md={4}></Col>
        <Col md={4}>
          <Card
            style={{
              width: "32rem",
            }}
          >
            <CardBody>
              <CardTitle tag="h1">Plata completa</CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Plata a avut loc cu succes
              </CardSubtitle>
            </CardBody>
            <BsPatchCheckFill className="payment-success-icon" />
            <CardBody>
              <CardText>
                In cateva momente contul va fi actualizat cu starea platii.
              </CardText>
              <Button onClick={onHomeClick}>Home</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default PaymentSuccess;
