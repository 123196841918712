// Packages
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
} from "reactstrap";
import axios from "axios";
// Utils
import {
  catchHandler,
  entriesToOptions,
  getRequestHeaders,
  getSelectStyles,
} from "../utils";
// Styling
import "./AddWhatsappGroup.css";

const AddWhatsappGroup = React.memo((props) => {
  const rootPathname = useMemo(() => "/whatsapp-groups", []);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    link: "",
    subject: "",
    location: "",
  });
  const [errors, setErrors] = useState({});
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [subjectsOptions, setSubjectsOptions] = useState([]);
  const history = useHistory();
  const catchHandlerBinded = catchHandler.bind(null, history);

  const getOptions = useCallback(() => {
    setLoading(true);
    Promise.all([
      axios.get(`/api/locations/select-locations`, getRequestHeaders({})),
      axios.get(`/api/subjects/select-subjects`, getRequestHeaders({})),
    ])
      .then(([resLocations, resSubjects]) => {
        const [{ data: dataLocations = [] }, { data: dataSubjects = [] }] = [
          resLocations,
          resSubjects,
        ];
        setLocationsOptions(dataLocations.map(entriesToOptions));
        setSubjectsOptions(dataSubjects.map(entriesToOptions));
      })
      .catch(catchHandlerBinded)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getOptions();
  }, []);

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setLoading(true);
      setErrors({});

      axios
        .post(
          "/api/whatsapp-groups/create-whatsapp-group",
          fields,
          getRequestHeaders()
        )
        .then(() => {
          toast.success("Grupul de Whatsapp a fost adaugat cu succes");
          history.push(rootPathname);
        })
        .catch((err) => {
          const {
            link = "",
            subject = "",
            location = "",
          } = catchHandler(history, err);
          setErrors({ link, subject, location });
          setLoading(false);
        });
    },
    [history, fields, rootPathname]
  );

  const onCancel = useCallback(
    (event) => {
      event.preventDefault();
      history.push(rootPathname);
    },
    [history, rootPathname]
  );

  const onInputChange = useCallback((event) => {
    const { target = {} } = event;
    const { name, value } = target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  const onSelectChange = useCallback((option, action) => {
    const { value = "" } = option || {};
    const { name = "" } = action || {};
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Card className="p-5 bg-light shadow-sm">
            <h2 className="mb-5 mx-auto text-center">
              Adaugare grup de Whatsapp
            </h2>
            <Form>
              <FormGroup>
                <Label for="link">Link</Label>
                <Input
                  id="link"
                  name="link"
                  type="text"
                  invalid={Boolean(errors["link"])}
                  value={fields["link"]}
                  onChange={onInputChange}
                />
                <FormFeedback>{errors["link"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="location">Locatie</Label>
                <Select
                  name="location"
                  className={Boolean(errors["location"]) ? "is-invalid" : ""}
                  placeholder="Alegeti locatia"
                  noOptionsMessage={() => <span>Fara rezultat</span>}
                  isClearable={true}
                  isLoading={loading}
                  onChange={onSelectChange}
                  styles={getSelectStyles(Boolean(errors["location"]))}
                  value={
                    locationsOptions.find(
                      ({ value }) => fields["location"] === value
                    ) || null
                  }
                  options={locationsOptions}
                />
                <FormFeedback>{errors["location"]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="subject">Disciplina</Label>
                <Select
                  name="subject"
                  className={Boolean(errors["subject"]) ? "is-invalid" : ""}
                  placeholder="Alegeti disciplina"
                  noOptionsMessage={() => <span>Fara rezultat</span>}
                  isClearable={true}
                  isLoading={loading}
                  onChange={onSelectChange}
                  styles={getSelectStyles(Boolean(errors["subject"]))}
                  value={
                    subjectsOptions.find(
                      ({ value }) => fields["subject"] === value
                    ) || null
                  }
                  options={subjectsOptions}
                />
                <FormFeedback>{errors["subject"]}</FormFeedback>
              </FormGroup>
              <FormGroup className="d-flex justify-content-between mt-5">
                <Button
                  type="submit"
                  color="primary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Salvare
                </Button>
                <Button outline color="secondary" onClick={onCancel}>
                  Renuntare
                </Button>
              </FormGroup>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
});

export default AddWhatsappGroup;
